/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { collection, DocumentData } from 'firebase/firestore';
import { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from '../../firebase-setup/firebase';
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	Link,
	MenuItem,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import InsuranceCostDialog from './InsuranceCostDialog';
import theme from '../../theme';

function MedicationList() {
	const [medications, loadingMedications] = useCollection(
		collection(db, 'newMedicationsCollection'),
		{ snapshotListenOptions: { includeMetadataChanges: false } }
	);
	const [insuranceData, loadingInsuranceData] = useCollection(
		collection(db, 'insuranceCompanies'),
		{ snapshotListenOptions: { includeMetadataChanges: false } }
	);

	const [medicationsList, setMedicationsList] = useState<DocumentData[] | null>(
		null
	);
	const [insuranceDataList, setInsuranceDataList] = useState<
		DocumentData[] | null
	>(null);
	const [insuranceProductList, setInsuranceProductList] = useState<
		DocumentData[] | null
	>(null);
	const [insurancePlanList, setInsurancePlanList] = useState<
		DocumentData[] | null
	>(null);
	const [insuranceValues, setInsuranceValues] = useState({
		company: '',
		product: '',
		plan: '',
	});
	const [isPAP, setIsPAP] = useState(true);
	const [openCostDialog, setOpenCostDialog] = useState<boolean>(false);
	const [selectedMed, setSelectedMed] = useState<any>({});
	const [filteredMedicationsList, setFilteredMedicationsList] = useState<
		DocumentData[] | null
	>(null);
	const [searchQuery, setSearchQuery] = useState<string>('');
	// Memoize sorted medications list to avoid re-sorting on every render
	const sortedMedicationsList = useMemo(() => {
		if (loadingMedications || !medications) return null;
		return medications.docs
			.map((doc) => doc.data())
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
	}, [loadingMedications, medications]);

	// Memoize sorted insurance data list
	const sortedInsuranceDataList = useMemo(() => {
		if (loadingInsuranceData || !insuranceData) return null;
		const list = insuranceData.docs
			.map((doc) => doc.data())
			.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
		list.unshift({ name: 'Medicare', products: [] });
		return list;
	}, [loadingInsuranceData, insuranceData]);

	// Set medications list and insurance data list only when they change
	useEffect(() => {
		setMedicationsList(sortedMedicationsList);
	}, [sortedMedicationsList]);

	useEffect(() => {
		setInsuranceDataList(sortedInsuranceDataList);
	}, [sortedInsuranceDataList]);
	// Filter medications list based on the search query
	useEffect(() => {
		if (!searchQuery) {
			setFilteredMedicationsList(medicationsList);
		} else {
			const filteredList = medicationsList?.filter(
				(medication) =>
					medication.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
					medication.genericName
						.toLowerCase()
						.includes(searchQuery.toLowerCase())
			);
			setFilteredMedicationsList(filteredList || null);
		}
	}, [searchQuery, medicationsList]);
	// Update insurance products and plans based on selected values
	useEffect(() => {
		const insuranceProducts = insuranceDataList?.find(
			(item) => item.name === insuranceValues.company
		);
		if (
			insuranceValues.company === "I don't have an insurance" ||
			insuranceValues.company === 'Medicare'
		) {
			setInsuranceProductList(null);
			setInsurancePlanList(null);
			return;
		}
		if (insuranceProducts) {
			const sortedProducts = insuranceProducts.products.sort((a: any, b: any) =>
				a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
			);
			setInsuranceProductList(sortedProducts);
		} else {
			setInsuranceProductList(null);
		}

		const insurancePlans = insuranceProductList?.find(
			(item) => item.name === insuranceValues.product
		);
		if (insurancePlans) {
			const sortedPlans = insurancePlans.plans.sort((a: any, b: any) =>
				a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
			);
			setInsurancePlanList(sortedPlans);
		} else {
			setInsurancePlanList(null);
		}
	}, [insuranceValues, insuranceDataList, insuranceProductList]);

	const handleInsuranceChange = useCallback((value: string | null) => {
		setInsuranceValues({ company: value || '', product: '', plan: '' });
		setInsuranceProductList(null);
		setInsurancePlanList(null);
	}, []);

	const handleProductChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setInsuranceValues((prev) => ({
				...prev,
				product: e.target.value,
				plan: '',
			}));
			if (e.target.value === 'All') {
				setIsPAP(true);
			}
			setInsurancePlanList(null);
		},
		[]
	);

	const handlePlanChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const selectedPlan = insurancePlanList?.find(
				(item) => item.name === e.target.value
			);
			setIsPAP(selectedPlan?.PAP === true);
			setInsuranceValues((prev) => ({ ...prev, plan: e.target.value }));
		},
		[insurancePlanList]
	);

	const handleClose = () => setOpenCostDialog(false);
	const handleClickOpen = () => setOpenCostDialog(true);

	return (
		<>
			{medicationsList &&
			medicationsList.length > 0 &&
			insuranceDataList &&
			insuranceDataList.length > 0 ? (
				<Stack
					spacing={2}
					alignItems={'center'}
					justifyContent={'center'}
					width={'100%'}
					sx={{
						paddingTop: {
							sm: 2,
							md: 8,
						},
					}}>
					<Typography
						variant='h1'
						textAlign={'center'}
						fontSize={{
							sm: '3rem',
							md: '4.8rem',
						}}
						color={'#153b61'}>
						Medication List
					</Typography>

					<Stack
						spacing={2}
						alignItems={'stretch'}
						width={'100%'}
						className='container'
						sx={{
							overflowX: 'clip',
						}}>
						<Stack width={'100%'} alignItems={'center'}>
							<Stack
								spacing={2}
								bgcolor={theme.palette.primary.main}
								maxWidth={{ xs: '100%', sm: '60%' }}
								p={2}
								borderRadius={2}>
								<Typography
									variant='body1'
									fontWeight={600}
									color={'white'}
									textAlign={'center'}>
									If the medication you need isn't on our formulary, You can
									check for generic equivalents within our list. Feel free to
									request us to add new medication in the appropriate form.
								</Typography>

								<Button
									sx={{
										alignSelf: 'center',
										'&:hover': {
											backgroundColor: 'white',
											color: theme.palette.primary.main,
										},
									}}
									variant='contained'
									color='primary'
									target='_blank'
									href='https://cd-clients.s3.eu-north-1.amazonaws.com/tprx/Medication+formulary+-+Transparent+Price+RX+-+Sep+2024+(2).pdf'>
									Download Medication List PDF
								</Button>
							</Stack>
						</Stack>
						<Grid container sx={{ width: '100%' }} spacing={2}>
							<Grid item xs={12} sm={3} key={'insurance company grid'}>
								<Autocomplete
									disablePortal
									value={insuranceValues['company'] || null}
									options={
										insuranceDataList?.map((option) => option.name) || []
									}
									onChange={(_, value) => handleInsuranceChange(value)}
									renderInput={(params) => (
										<TextField
											{...params}
											required
											fullWidth
											name={`company`}
											value={insuranceValues['company'] || ''}
											label={'Insurance Company'}
											variant='outlined'
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12} sm={3} key={'insurance product grid'}>
								<TextField
									key={'insurance product'}
									fullWidth
									select
									required={
										insuranceValues['company'] === `I don't have an insurance`
											? false
											: true
									}
									name={'product'}
									value={insuranceValues['product'] || ''}
									label={'Insurance Product'}
									onChange={handleProductChange}
									variant='outlined'>
									{insuranceProductList ? (
										insuranceProductList?.map((item) => (
											<MenuItem key={item.name} value={item.name}>
												{item.name}
											</MenuItem>
										))
									) : (
										<MenuItem value={''}>No Products Available</MenuItem>
									)}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={3} key={'insurance plan grid'}>
								<TextField
									key={'insurance plan'}
									fullWidth
									select
									required={
										(insurancePlanList && insurancePlanList.length === 1) ||
										insuranceValues['company'] === `I don't have an insurance`
											? false
											: true
									}
									name={'plan'}
									value={insuranceValues['plan'] || ''}
									label={'Insurance Plan'}
									onChange={handlePlanChange}
									variant='outlined'>
									{insurancePlanList ? (
										insurancePlanList?.map((item) => (
											<MenuItem
												key={item.name}
												value={item.name ? item.name : ''}>
												{item.name ? item.name : 'All'}
											</MenuItem>
										))
									) : (
										<MenuItem value={''}>No Plans Available</MenuItem>
									)}
								</TextField>
							</Grid>
							<Grid item xs={12} sm={3} key={'search medications grid'}>
								<TextField
									fullWidth
									label='Search Medication'
									variant='outlined'
									value={searchQuery}
									onChange={(e) => setSearchQuery(e.target.value)}
									sx={{ marginBottom: 4 }}
								/>
							</Grid>
						</Grid>
						{insuranceValues.plan !== '' ||
						insuranceValues.product === 'All' ||
						insuranceValues.company === 'Medicare' ||
						insuranceValues.company === "I don't have an insurance" ? (
							<Box
								sx={{
									overflowX: 'auto',
									xs: { width: '100svw' },
								}}>
								<Grid
									container
									borderBottom={1}
									pb={1}
									wrap='nowrap'
									width={{ xs: '66rem', sm: 'auto' }}>
									<Grid
										item
										xs={4}
										sx={{
											xs: {
												flex: 1,
												maxWidth: 'unset',
												width: '20rem',
												marginRight: '2rem',
											},
										}}>
										<Typography variant='h3' color={'#153b61'} noWrap>
											Medication Name
										</Typography>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											xs: {
												flex: 1,
												maxWidth: 'unset',
												width: '20rem',
												marginRight: '2rem',
											},
										}}>
										<Typography variant='h3' color={'#153b61'}>
											Strength
										</Typography>
									</Grid>
									<Grid
										item
										xs={4}
										sx={{
											xs: {
												flex: 1,
												maxWidth: 'unset',
												width: '20rem',
												marginRight: '2rem',
											},
										}}>
										<Typography variant='h3' color={'#153b61'}>
											Price
										</Typography>
									</Grid>
								</Grid>
								{filteredMedicationsList?.map((medication, index) => (
									<Grid
										key={index}
										container
										borderBottom={1}
										pb={1}
										wrap='nowrap'
										width={{ xs: '66rem', sm: '100%' }}>
										<Grid
											item
											xs={4}
											sx={{
												xs: {
													flex: 1,
													maxWidth: 'unset',
													width: '20rem',
													marginRight: '2rem',
												},
											}}>
											<Typography
												variant='body1'
												fontWeight={600}
												color={'#153b61'}>
												{medication.brand
													? medication.name
													: medication.genericName !== '-'
													? medication.genericName
													: medication.name}
											</Typography>
											<Stack>
												<Typography
													variant='body2'
													sx={{
														marginTop: 0,
													}}>
													{medication.brand
														? 'Brand Medication'
														: medication.genericName !== '-' && 'Generic for:'}
												</Typography>
												<Typography
													variant='body2'
													sx={{
														marginTop: 0,
													}}>
													{medication.brand
														? medication.genericName
														: medication.genericName !== '-' && medication.name}
												</Typography>
											</Stack>
										</Grid>
										<Grid
											item
											xs={4}
											sx={{
												xs: {
													flex: 1,
													maxWidth: 'unset',
													width: '20rem',
													marginRight: '2rem',
												},
											}}>
											{medication.options.map((option: any, index: number) => (
												<Grid key={index}>
													<Typography variant='body2'>
														{option.strength}
													</Typography>
												</Grid>
											))}
										</Grid>
										<Grid
											item
											xs={4}
											sx={{
												xs: {
													flex: 1,
													maxWidth: 'unset',
													width: '20rem',
													marginRight: '2rem',
												},
											}}>
											{medication.options.map((option: any, index: number) =>
												isPAP ? (
													<Grid key={index}>
														<Typography variant='body2'>
															{option.price === 'No cost'
																? `${option.price}, refilled every 90 days`
																: `${option.price} for 90 days supply`}
														</Typography>
													</Grid>
												) : medication.brand ? (
													<Grid key={index}>
														<Typography variant='body1' fontWeight={600}>
															<Link
																onClick={() => {
																	setSelectedMed(medication);
																	handleClickOpen();
																}}
																style={{ cursor: 'pointer' }}>
																View price estimation
															</Link>
														</Typography>{' '}
													</Grid>
												) : (
													<Grid key={index}>
														<Typography variant='body2'>
															{option.price} for 90 days supply
														</Typography>
													</Grid>
												)
											)}
										</Grid>
									</Grid>
								))}
							</Box>
						) : (
							<Stack alignItems={'center'}>
								<Typography variant='h3' fontWeight={600}>
									Please choose an insurance product to view the aviailable
									medications.
								</Typography>
							</Stack>
						)}
					</Stack>
					{insuranceValues &&
						insuranceValues.company !== '' &&
						insuranceValues.product !== '' &&
						insuranceValues.plan !== '' &&
						selectedMed.brand && (
							<InsuranceCostDialog
								key={selectedMed.name}
								open={openCostDialog}
								handleClose={handleClose}
								selectedMed={selectedMed}
								insuranceData={insuranceValues}
							/>
						)}
				</Stack>
			) : (
				<div className='loader-s'>
					<div className='loader' />
				</div>
			)}
		</>
	);
}
export default MedicationList;
