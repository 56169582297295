/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import formConfig from './formConfig';
import { clientInfo, formConfigField, formValues } from '../../utils/types';
import AutocompleteField from './AutoCompleteField';
import { formatPhoneNumber } from '../../utils/utilFuntions';

function ThirdStep({
	handleChange,
	handleChangeSelect,
	values,
	setValues,
	setValidStep,
}: {
	handleChange: (
		event: React.ChangeEvent<HTMLInputElement>,
		object: clientInfo
	) => void;
	handleChangeSelect: (
		event: React.ChangeEvent<HTMLInputElement>,
		object: clientInfo
	) => void;

	values: formValues;
	setValues: React.Dispatch<React.SetStateAction<formValues>>;
	setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [errors, setErrors] = useState<{ [key: string]: string }>({});

	const handleChangeWithValidation = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { name, value } = event.target;

		// Perform validation
		let error = '';
		if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
			error = 'Invalid email address';
		} else if (
			(name === 'phone' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) ||
			(name === 'emerContactPhone' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value))
		) {
			error = 'Invalid phone number';
		} else if (name === 'ssn' && !/^\d{3}-\d{2}-\d{4}$/.test(value)) {
			error = 'Invalid Social Security Number';
		} else if (name === 'zip' && !/^\d{5}$/.test(value)) {
			error = 'Invalid Zip Code';
		} else if (name === 'address' && value === '') {
			error = 'Invalid Address';
		}

		// Update errors state
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));

		// Call setValues with the full object, not just a single field
		setValues((prevValues) => ({
			...prevValues,
			patient: {
				...prevValues.patient,
				[name]: value,
			},
		}));
	};

	// Format SSN
	function formatSSN(input: string) {
		const cleanedInput = input.replace(/\D/g, '');
		const limitedInput = cleanedInput.slice(0, 9);
		return limitedInput.replace(
			/^(\d{3})(\d{2})(\d{0,4})$/,
			(_, p1, p2, p3) => {
				return p3 ? `${p1}-${p2}-${p3}` : p2 ? `${p1}-${p2}` : p1;
			}
		);
	}

	// Format ZIP code
	function formatZip(value: string) {
		return value.replace(/\D/g, '').substring(0, 5);
	}

	// Validate required fields
	const checkRequired = useCallback(() => {
		let valid = true;

		// Check all fields in the form
		formConfig[2].fields.forEach((field: formConfigField) => {
			const fieldValue = values.patient[field.name as keyof clientInfo];
			if (field.required && !fieldValue) {
				valid = false;
			}
		});

		// Check if there are any errors
		if (Object.values(errors).some((error) => error !== '')) {
			valid = false;
		}

		setValidStep(valid);
	}, [errors, setValidStep, values.patient]);

	// Run validation whenever the patient values or errors change
	useEffect(() => {
		checkRequired();
	}, [values.patient, errors, checkRequired]);

	return (
		<>
			<Stack spacing={2} alignItems={'center'}>
				<Typography variant='h2' textAlign={'center'}>
					Please fill in your details below
				</Typography>
				<Box className='tprx-card' sx={{ maxWidth: '66rem' }}>
					<Grid container spacing={2}>
						{formConfig[2].fields.map((field: formConfigField) => (
							<Grid
								item
								xs={12}
								sm={field.type === 'address' ? 12 : 6}
								key={field.name}>
								{(() => {
									switch (field.type) {
										case 'radio':
											return (
												<FormControl required={field.required}>
													<FormLabel id='gender'>{field.label}</FormLabel>
													<RadioGroup
														aria-labelledby='gender'
														name='sex'
														value={values.patient.sex}
														onChange={(e: ChangeEvent<HTMLInputElement>) => {
															handleChange(e, values.patient);
														}}>
														<Stack direction='row' spacing={2}>
															<FormControlLabel
																value='Male'
																control={<Radio />}
																label='Male'
																sx={{
																	pr: 2,
																	border: '1px solid #d9d9d9',
																	borderRadius: '4px',
																	backgroundColor: '#fff',
																}}
															/>
															<FormControlLabel
																value='Female'
																control={<Radio />}
																label='Female'
																sx={{
																	pr: 2,
																	border: '1px solid #d9d9d9',
																	borderRadius: '4px',
																	backgroundColor: '#fff',
																}}
															/>
														</Stack>
													</RadioGroup>
												</FormControl>
											);
										case 'select':
											return (
												<TextField
													required={field.required}
													select
													label={field.label}
													name={field.name}
													value={
														values.patient[field.name as keyof clientInfo] || ''
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														handleChangeSelect(e, values.patient);
													}}
													margin='normal'
													fullWidth>
													{field.options!.map((option) => (
														<MenuItem key={option} value={option}>
															{option}
														</MenuItem>
													))}
												</TextField>
											);
										case 'address':
											return (
												<AutocompleteField
													label={field.label}
													name={field.name}
													value={values.patient.fullAddress || ''}
													onChange={(addressValues: any) => {
														Object.keys(addressValues).forEach((key) => {
															handleChangeWithValidation({
																target: {
																	name: key,
																	value: addressValues[key],
																},
															} as ChangeEvent<HTMLInputElement>);
														});
													}}
													errors={errors['fullAddress']}
													setErrors={setErrors}
													errorObjectName='fullAddress'
												/>
											);
										default:
											return (
												<TextField
													required={field.required}
													label={field.label}
													name={field.name}
													type={field.type}
													value={
														values.patient[field.name as keyof clientInfo] || ''
													}
													onChange={(e: ChangeEvent<HTMLInputElement>) => {
														if (field.name === 'ssn') {
															e.target.value = formatSSN(e.target.value);
														} else if (
															field.name === 'phone' ||
															field.name === 'emerContactPhone'
														) {
															formatPhoneNumber(e);
														} else if (field.name === 'zip') {
															e.target.value = formatZip(e.target.value);
														}
														handleChangeWithValidation(e);
													}}
													error={!!errors[field.name]}
													helperText={errors[field.name]}
													margin='normal'
													fullWidth
												/>
											);
									}
								})()}
							</Grid>
						))}
					</Grid>
				</Box>
			</Stack>
		</>
	);
}

export default ThirdStep;
