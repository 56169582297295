import { useEffect, useRef, useState } from 'react';
import './App.css';
import Navigation from './Componenets/client portal/Navigation';
import {
	userState,
	userDataState,
	healthcareDataState,
	ordersDataState,
	userPhoneNumberState,
} from './main';
import PersonalArea from './Componenets/client portal/PersonalArea';
import { Routes, Route, Outlet, BrowserRouter } from 'react-router-dom';
import PersonalInfo from './Componenets/client portal/PersonalInfo';
import { DocumentData, collection } from 'firebase/firestore';
import LoginPage from './Componenets/Login/LoginPage';
import LoginProvider from './Componenets/Login/LoginProvider';
import { User } from 'firebase/auth';
import HealthcareProviders from './Componenets/client portal/HealthcareProviders';
import Orders from './Componenets/client portal/Orders';
import { useCollection } from 'react-firebase-hooks/firestore';
import { db } from './firebase-setup/firebase';
import { FAProvider } from './Componenets/Login/TwoFA';
import BrandTracking from './Componenets/client portal/BrandTracking';
import GenericTracking from './Componenets/client portal/GenericTracking';
import Intercom from '@intercom/messenger-js-sdk';
import SupportHub from './Componenets/client portal/SupportHub';
import ApplyForm from './Componenets/applicationForm/ApplyForm';
import InsurancePortal from './Componenets/insurance-portal/InsurancePortal';
import EligibilityCalculator from './Componenets/broker-portal/eligibilityCalculator';
import MedicationList from './Componenets/broker-portal/medicationList';
import RCSBrandTracking from './Componenets/client portal/RCSBrandTracking';
import Smartlook from 'smartlook-client';
import WelcomScreen from './Componenets/applicationForm/welcome/WelcomeScreen';

function App() {
	const [user, setUser] = useState<User | null>(null);
	const [userData, setUserData] = useState<DocumentData | null>(null);
	const [healthcareData, setHealthcareData] = useState<DocumentData[] | null>(
		null
	);
	const [ordersData, setOrdersData] = useState<DocumentData[] | null>(null);
	const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null);
	const [medications, loadingMedications] = useCollection(
		collection(db, 'newMedicationsCollection'),
		{
			snapshotListenOptions: { includeMetadataChanges: false },
		}
	);
	const medicationsList = useRef<DocumentData[] | null>(null);

	useEffect(() => {
		if (loadingMedications) return;
		medicationsList.current = medications!.docs.map((doc) => doc.data());
		medicationsList.current.sort((a, b) => {
			if (a.name > b.name) return 1;
			if (a.name < b.name) return -1;
			return 0;
		});
	}, [loadingMedications, medications]);

	useEffect(() => {
		if (!userData) return;
		Intercom({
			app_id: 'sr0dlb76',
			email: userData.email,
			name: userData.fname + ' ' + userData.lname,
		});
	}, [userData]);
	useEffect(() => {
		if (!Smartlook.initialized)
			Smartlook.init('378468602e6da2b4153d87acda40f4a3bf20cf23');
	}, []);
	return (
		<>
			<userState.Provider value={{ user, setUser }}>
				<userPhoneNumberState.Provider
					value={{ userPhoneNumber, setUserPhoneNumber }}>
					<FAProvider>
						<userDataState.Provider value={{ userData, setUserData }}>
							<healthcareDataState.Provider
								value={{ healthcareData, setHealthcareData }}>
								<ordersDataState.Provider value={{ ordersData, setOrdersData }}>
									<BrowserRouter>
										<Routes>
											<Route path={'/login'} element={<LoginPage />} />
											<Route path={'/enroll'} element={<ApplyForm />} />
											<Route path={'/welcome'} element={<WelcomScreen />} />
											<Route
												path={'/chc-portal'}
												element={<InsurancePortal />}
											/>
											<Route
												path='/eligibility-calc'
												element={<EligibilityCalculator />}
											/>
											<Route path='/drug-lookup' element={<MedicationList />} />
											<Route
												path={''}
												element={
													<LoginProvider>
														<Navigation>
															<Outlet />
														</Navigation>
													</LoginProvider>
												}>
												<Route
													index
													element={
														userData ? (
															<PersonalArea
																medicationList={medicationsList.current}
															/>
														) : (
															<div className='loader-c'>
																<div className='loader' />
															</div>
														)
													}
												/>
												<Route
													path='/personalInfo'
													element={
														userData ? (
															<PersonalInfo />
														) : (
															<div className='loader-c'>
																<div className='loader' />
															</div>
														)
													}
												/>
												<Route
													path='/healthcareProviders'
													element={healthcareData && <HealthcareProviders />}
												/>
												<Route
													path='/orders'
													element={
														ordersData && medications ? (
															<Orders
																medicationList={medicationsList.current}
															/>
														) : (
															<div className='loader-c'>
																<div className='loader' />
															</div>
														)
													}
												/>
												<Route
													path='/brandTracking'
													element={
														<BrandTracking
															medicationList={medicationsList.current}
															healthcareData={healthcareData}
														/>
													}
												/>
												<Route
													path='/RCSTracking'
													element={<RCSBrandTracking />}
												/>
												<Route
													path='/genericTracking'
													element={<GenericTracking />}
												/>
												<Route path='/SupportHub' element={<SupportHub />} />
											</Route>
										</Routes>
									</BrowserRouter>
								</ordersDataState.Provider>
							</healthcareDataState.Provider>
						</userDataState.Provider>
					</FAProvider>
				</userPhoneNumberState.Provider>
			</userState.Provider>
		</>
	);
}

export default App;
