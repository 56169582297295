/* eslint-disable @typescript-eslint/no-explicit-any */
import { docInfo, orderInfo } from './types';

const values = JSON.parse(sessionStorage.getItem('formData') || '{}');
const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
export async function sendLogToSlack(values: any) {
	const getQueryparams = () => {
		const url = new URL(window.location.href);
		const searchParams = new URLSearchParams(url.search);
		return searchParams;
	};
	const isCHC = getQueryparams().get('utm_campaign') === 'chc';
	const message = {
		blocks: [
			{
				type: 'header',
				text: {
					type: 'plain_text',
					text: 'New Application Submission Before Payment',
					emoji: true,
				},
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Source:*\n${isCHC ? 'CHC' : 'website'}`,
					},
					{
						type: 'mrkdwn',
						text: `*Agent Details:*\n${values.patient.agentName} + ${values.patient.agentId}`,
					},
				],
			},
			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Patient:*\nFirst Name: ${values.patient.fname}\nMiddle Name: ${values.patient.mname}\nLast Name: ${values.patient.lname}\nApt: ${values.patient.apt}\nAddress: ${values.patient.fullAddress}\nSSN: ${values.patient.ssn}\nBirthday: ${values.patient.dob}\nEmail: ${values.patient.email}\nIncome: ${values.patient.income}`,
					},
					{
						type: 'mrkdwn',
						text: `*Insurance:*\nCompany: ${values.patient.insurance.company}\nProduct: ${values.patient.insurance.product}\nPlan: ${values.patient.insurance.plan}\nPolicy: ${values.patient.insurance.policy}`,
					},
					{
						type: 'mrkdwn',
						text: `*Medicare:*\nPart D: ${values.patient.medicare.partD}\nAdvantage: ${values.patient.medicare.advantage}\nSupplement: ${values.patient.medicare.supplement}\nInsurance Name: ${values.patient.medicare.insuranceName}\nInsurance Address: ${values.patient.medicare.insuranceAddress}\nPlan Name: ${values.patient.medicare.planName}\nPlan Phone: ${values.patient.medicare.planPhone}\nGroup Number: ${values.patient.medicare.groupNumber}\nRxBIN: ${values.patient.medicare.rxbin}\nRxPCN: ${values.patient.medicare.rxpcn}\nRxGroup: ${values.patient.medicare.rxgroup}\nRxMember ID: ${values.patient.medicare.rxmemberid}\nPart Aid: ${values.patient.medicare.partAid}\nMBI: ${values.patient.medicare.mbi}`,
					},
				],
			},
			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: values.doctors.map((doctor: docInfo) => ({
					type: 'mrkdwn',
					text: `*Doctor:*\nFirst Name: ${doctor.fname}\nMiddle Name: ${doctor.mname}\nLast Name: ${doctor.lname}\nEmail: ${doctor.email}\nPhone: ${doctor.phone}\nFax: ${doctor.fax}\nAddress: ${doctor.fullAddress}, ${doctor.zip}, suite:${doctor.suite}\nFacility: ${doctor.facility}`,
				})),
			},
			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: values.orders.map((order: orderInfo) => ({
					type: 'mrkdwn',
					text: `*Order:*\nDoctor Name: ${order.doctorName}\nMedication Name: ${order.medicationName}\nMedication Strength: ${order.medicationStrength}`,
				})),
			},
			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Agreements:*\nIncome Statement: ${values.agreements.incomeStatement.toString()}\nPolicies Statement: ${values.agreements.policiesStatement.toString()}\nInformation Statement: ${values.agreements.informationStatement.toString()}\nEffective Statement: ${values.agreements.effectiveStatement.toString()}`,
					},
				],
			},
		],
	};
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/slackSubmission',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(message),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const res = await response.json();
		return res;
	} catch (err) {
		console.log(err);
	}
}

export async function sendErrorToSlack(errorDetails: any) {
	if (errorDetails.environment === 'development') return;
	const message = {
		blocks: [
			{
				type: 'header',
				text: {
					type: 'plain_text',
					text: '🚨 *Error Occurred* 🚨',
					emoji: true,
				},
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Message:*\n${errorDetails.message}`,
					},
				],
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Stack:*\n${errorDetails.stack}`,
					},
					{
						type: 'mrkdwn',
						text: `*Environment:*\n${errorDetails.environment}`,
					},
					{
						type: 'mrkdwn',
						text: `*User Agent:*\n${errorDetails.userAgent}`,
					},
					{
						type: 'mrkdwn',
						text: `*Timestamp:*\n${new Date(
							errorDetails.timestamp
						).toISOString()}`,
					},
					{
						type: 'mrkdwn',
						text: `*URL:*\n${errorDetails.url}`,
					},
				],
			},
			{
				type: 'divider',
			},
		],
	};

	if (values.patient) {
		message.blocks.push(
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: '*Source:*\nCHC',
					},
					{
						type: 'mrkdwn',
						text: `*Agent Details:*\n${values.patient.agentName} + ${values.patient.agentId}`,
					},
				],
			},
			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Patient:*\nFirst Name: ${values.patient.fname}\nMiddle Name: ${values.patient.mname}\nLast Name: ${values.patient.lname}\nApt: ${values.patient.apt}\nAddress: ${values.patient.fullAddress}\nSSN: ${values.patient.ssn}\nBirthday: ${values.patient.dob}\nEmail: ${values.patient.email}\nIncome: ${values.patient.income}`,
					},
					{
						type: 'mrkdwn',
						text: `*Insurance:*\nCompany: ${values.patient.insurance.company}\nProduct: ${values.patient.insurance.product}\nPlan: ${values.patient.insurance.plan}\nPolicy: ${values.patient.insurance.policy}`,
					},
					{
						type: 'mrkdwn',
						text: `*Medicare:*\nPart D: ${values.patient.medicare.partD}\nAdvantage: ${values.patient.medicare.advantage}\nSupplement: ${values.patient.medicare.supplement}\nInsurance Name: ${values.patient.medicare.insuranceName}\nInsurance Address: ${values.patient.medicare.insuranceAddress}\nPlan Name: ${values.patient.medicare.planName}\nPlan Phone: ${values.patient.medicare.planPhone}\nGroup Number: ${values.patient.medicare.groupNumber}\nRxBIN: ${values.patient.medicare.rxbin}\nRxPCN: ${values.patient.medicare.rxpcn}\nRxGroup: ${values.patient.medicare.rxgroup}\nRxMember ID: ${values.patient.medicare.rxmemberid}\nPart Aid: ${values.patient.medicare.partAid}\nMBI: ${values.patient.medicare.mbi}`,
					},
				],
			},
			{
				type: 'divider',
			},

			...values.doctors.map((doctor: docInfo) => ({
				type: 'section',
				text: {
					type: 'mrkdwn',
					text: `*Doctor:*\nFirst Name: ${doctor.fname}\nMiddle Name: ${doctor.mname}\nLast Name: ${doctor.lname}\nEmail: ${doctor.email}\nPhone: ${doctor.phone}\nFax: ${doctor.fax}\nAddress: ${doctor.fullAddress}, ${doctor.zip}, suite:${doctor.suite}\nFacility: ${doctor.facility}`,
				},
			})),

			{
				type: 'divider',
			},
			...values.orders.map((order: orderInfo) => ({
				type: 'section',
				text: {
					type: 'mrkdwn',
					text: `*Order:*\nDoctor Name: ${order.doctorName}\nMedication Name: ${order.medicationName}\nMedication Strength: ${order.medicationStrength}`,
				},
			})),

			{
				type: 'divider',
			},
			{
				type: 'section',
				fields: [
					{
						type: 'mrkdwn',
						text: `*Agreements:*\nIncome Statement: ${values.agreements.incomeStatement.toString()}\nPolicies Statement: ${values.agreements.policiesStatement.toString()}\nInformation Statement: ${values.agreements.informationStatement.toString()}\nEffective Statement: ${values.agreements.effectiveStatement.toString()}`,
					},
				],
			}
		);
	} else if (userData) {
		message.blocks.push({
			type: 'section',
			fields: [
				{
					type: 'mrkdwn',
					text: `*user email: ${userData.email}`,
				},
				{
					type: 'mrkdwn',
					text: `*user uniqueId: ${userData.uniqueId}`,
				},
			],
		});
	}
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/slackErrors',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(message),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}
	} catch (err) {
		console.log(err);
	}
}
