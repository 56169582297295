/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DynamicStep from './DynamicStep';
import { FormControl, Stack, Typography } from '@mui/material';
import { formValues } from '../../utils/types';
import { useEffect, useMemo, useState } from 'react';
import { DocumentData, Timestamp } from 'firebase/firestore';
import useMediaQuery from '@mui/material/useMediaQuery';
import theme from '../../theme';
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase-setup/firebase';

const steps = [
	'Qualification',
	'Medications',
	'Personal Details',
	'Healthcare Provider',
	'Confirmation',
];

function FormStepper({
	medicationsList,
	insuranceDataList,
}: {
	medicationsList: DocumentData[] | null;
	insuranceDataList: DocumentData[] | null;
}) {
	const [activeStep, setActiveStep] = useState(0);
	const [formValues, setFormValues] = useState<formValues>({
		patient: {
			timestamp: Timestamp.now(),
			fname: '',
			lname: '',
			mname: '',
			dob: '',
			ssn: '',
			address: '',
			apt: '',
			city: '',
			state: '',
			country: 'USA',
			zip: '',
			fullAddress: '',
			phone: '',
			email: '',
			marital: '',
			employed: '',
			sex: 'Male',
			disabled: false,
			numinhouse: '',
			emerContactName: '',
			emerContactPhone: '',
			income: '',
			agentName: '',
			agentId: '',
			insurance: {
				company: '',
				product: '',
				plan: '',
				policy: '',
			},
			medicare: {
				partD: '',
				advantage: '',
				supplement: '',
				insuranceName: '',
				insuranceAddress: '',
				planName: '',
				planPhone: '',
				groupNumber: '',
				rxbin: '',
				rxpcn: '',
				rxgroup: '',
				rxmemberid: '',
				partAid: '',
				mbi: '',
			},
			rcs: {
				programEnrolled: false,
				condition: '',
			},
			isPAP: true,
			isCHC: false,
		},
		doctors: [
			{
				fname: '',
				mname: '',
				lname: '',
				email: '',
				phone: '',
				fax: '',
				faxValidate: '',
				address: '',
				city: '',
				state: '',
				zip: '',
				fullAddress: '',
				country: 'USA',
				facility: '',
				suite: '',
			},
		],
		orders: [
			{
				doctorName: '',
				medicationName: '',
				medicationStrength: '',
			},
			{
				doctorName: '',
				medicationName: '',
				medicationStrength: '',
			},
			{
				doctorName: '',
				medicationName: '',
				medicationStrength: '',
			},
			{
				doctorName: '',
				medicationName: '',
				medicationStrength: '',
			},
		],
		agreements: {
			incomeStatement: false,
			policiesStatement: false,
			informationStatement: false,
			effectiveStatement: false,
		},
	});
	const memoizedFormValues = useMemo(() => formValues, [formValues]);

	useEffect(() => {
		sessionStorage.setItem('formData', JSON.stringify(formValues));
	}, [formValues]);

	const matches = useMediaQuery('(max-width:991px)');

	useEffect(() => {
		logEvent(analytics, `form_step - ${activeStep}`);
		const url = new URL(window.location.href);
		url.searchParams.set('step', activeStep.toString());
		window.history.pushState({}, '', url);
	}, [activeStep]);

	function fillValuesFromQueryParams() {
		const url = new URL(window.location.href);
		const searchParams = new URLSearchParams(url.search);
		searchParams.forEach((value, key) => {
			if (value === '') return;
			if (key === 'company' && value === 'Medicare') {
				setFormValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						insurance: {
							...prev.patient.insurance,
							company: value,
						},
					},
				}));
			} else if (key === 'householdSize') {
				setFormValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						numinhouse: value,
					},
				}));
			} else if (key === 'income') {
				setFormValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						income: value.replace(/\D/g, ''),
					},
				}));
			} else if (key === 'medicationName') {
				setFormValues((prev) => ({
					...prev,
					orders: [
						{
							...prev.orders[0],
							medicationName: value,
						},
						...prev.orders.slice(1),
					],
				}));
			} else if (key === 'utm_campaign' && value === 'chc') {
				setFormValues((prev) => ({
					...prev,
					patient: {
						...prev.patient,
						isCHC: true,
					},
				}));
			}
		});
	}
	useEffect(() => {
		fillValuesFromQueryParams();
	}, []);

	return (
		<Stack
			sx={{ width: '100%' }}
			gap={{
				xs: 2,
				md: 8,
			}}>
			{matches ? (
				<Stack
					spacing={2}
					p={2}
					direction={'row'}
					justifyContent={'center'}
					alignItems={'center'}>
					<Stack
						justifyContent={'center'}
						alignItems={'center'}
						p={1}
						sx={{
							borderRadius: 99,
							backgroundColor: theme.palette.primary.main,
							width: 32,
							height: 32,
							color: 'white',
						}}>
						<Typography variant='body1' fontWeight={600}>
							{activeStep}
						</Typography>
					</Stack>
					<Typography variant='body1' fontWeight={600}>
						{steps[activeStep]}
					</Typography>
				</Stack>
			) : (
				<Stepper activeStep={activeStep}>
					{steps.map((label) => {
						const stepProps: { completed?: boolean } = {};
						const labelProps: {
							optional?: React.ReactNode;
						} = {};
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
			)}
			<FormControl>
				<DynamicStep
					values={memoizedFormValues}
					setValues={setFormValues}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					medicationsList={medicationsList}
					insuranceDataList={insuranceDataList}
				/>
			</FormControl>
		</Stack>
	);
}

const MemoizedFormStepper = React.memo(FormStepper);
export default MemoizedFormStepper;
