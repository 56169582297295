/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import '../../index.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from '@mui/material';

import { navigateWithQuery } from '../../utils/utilFuntions';

function RCSBrandTracking() {
	const navigate = useNavigate();
	const location = useLocation();

	const [step, setStep] = useState<number>(0);
	const [screen, setScreen] = useState(0);

	const Step1Screen1 = () => (
		<Stack direction={'column'}>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Initial Steps
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Enrollment Confirmation
				</Typography>
				<Typography variant='body1' className='step-text'>
					Have you received your enrollment confirmation email from
					TransparentpriceRX?
				</Typography>
				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, Email Recieved
				</Button>
			</Stack>
		</Stack>
	);
	const Step1Screen2 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Initial Steps
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Welcome Email:
				</Typography>
				<Typography variant='body1' className='step-text'>
					Within 72 hours of enrollment, you will receive a Welcome Email from
					TransparentpriceRX detailing the next steps. Have you checked your
					inbox (and spam folder) for the Welcome Email from TransparentpriceRX?
				</Typography>
				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, I've Read and Understand
				</Button>
			</Stack>
		</>
	);
	const Step2Screen1 = () => (
		<>
			<Typography variant='h3' className='step-title' mb={4}>
				Savings Card
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Manufacturer Contact
				</Typography>
				<Typography variant='body1' className='step-text'>
					After your enrollment, you will be contacted by the manufacturer
					regarding your Savings Card and other pertinent details. Have you been
					contacted by the manufacturer?
				</Typography>
				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, I've been contacted
				</Button>
			</Stack>
		</>
	);
	const Step2Screen2 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Savings Card
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Receiving Your Savings Card
				</Typography>
				<Typography variant='body1' className='step-text'>
					You will receive your Savings Card either by email or through a
					physical card mailed to your address. Note that activation
					requirements may vary.Have you received your Savings Card?
				</Typography>
				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, I have the card
				</Button>
			</Stack>
		</>
	);
	const Step2Screen3 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Savings Card
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Activate Savings Card (if applicable)
				</Typography>
				<Typography variant='body1' className='step-text'>
					Have you hand-delivered or mailed the physical signed application and
					income verification to your healthcare provider?
				</Typography>

				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, Delivered
				</Button>
			</Stack>
		</>
	);
	const Step3Screen1 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Healthcare Provider Process
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Contact Healthcare Provider
				</Typography>
				<Typography variant='body1' className='step-text'>
					Request your healthcare provider to send your prescription to the
					pharmacy of your choice. This step ensures that your pharmacy receives
					the correct prescription information directly. Have you requested your
					healthcare provider to send your prescription to your chosen pharmacy?
				</Typography>

				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes I Activated
				</Button>
			</Stack>
		</>
	);
	const Step3Screen2 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Healthcare Provider Process
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Submit EOB for Savings Programs
				</Typography>
				<Typography variant='body1' className='step-text'>
					For certain medications, submitting an Explanation of Benefits (EOB)
					is required to activate savings. We have informed your healthcare
					provider about this process. Medications that require EOB submission:
					Botox for migraines, Skyrizi, Enbrel, Simponi Aria, Stelara, Remicade
					and Infliximab. Did you submit your EOB, or did you ask for your
					healthcare provider's assistance in submitting it to the
					manufacturer?"
				</Typography>

				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, it's done
				</Button>
			</Stack>
		</>
	);
	const Step4Screen1 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Conclusion
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='h2' textAlign={'center'}>
					Using Your Savings Card
				</Typography>
				<Typography variant='body1' className='step-text'>
					Present your activated Savings Card at your chosen pharmacy when
					picking up your medication to apply your savings. This Saving Card
					will be effective once your health insurance is activated. Have you
					used your Savings Card at your chosen pharmacy?
				</Typography>
				<Button
					variant='contained'
					onClick={nextStep}
					endIcon={
						<svg
							width='15'
							height='9'
							viewBox='0 0 15 9'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'>
							<path
								d='M0.976055 4.987V3.664H11.7131L8.94106 0.891999L9.76006 0.0729996L14.0171 4.33L9.76006 8.587L8.94106 7.768L11.7221 4.987H0.976055Z'
								fill='currentColor'
							/>
						</svg>
					}>
					Yes, I've used it
				</Button>
			</Stack>
		</>
	);
	const Step4Screen2 = () => (
		<>
			<Typography variant='h3' textAlign={'center'} mb={4}>
				Conclusion
			</Typography>
			<Stack
				className='tprx-card'
				style={{ maxWidth: '93rem', margin: '0 auto' }}>
				<Typography variant='body1' className='step-text'>
					Thank you for following the steps. You are now fully set up in the
					Real Cost Savings Program. For any further questions, to add
					medications, use the client portal, or to chat with our support team,
					please continue navigating the Client Portal
				</Typography>
				<Button
					variant='contained'
					className='cta-main'
					onClick={() => {
						navigate('/');
					}}>
					Back to Home
				</Button>
			</Stack>
		</>
	);
	const nextStep = () => {
		if (step === 0) {
			if (screen === 1) {
				setStep(step + 1);
				setScreen(0);
			} else {
				setScreen(screen + 1);
			}
		} else if (step === 1) {
			if (screen === 2) {
				setStep(step + 1);
				setScreen(0);
			} else {
				setScreen(screen + 1);
			}
		} else if (step === 2 || step === 3) {
			if (screen === 1) {
				setStep(step + 1);
				setScreen(0);
			} else {
				setScreen(screen + 1);
			}
		}
	};

	return (
		<Stack className='container'>
			<button
				className='back-btn'
				onClick={() => {
					navigateWithQuery(navigate, '/', location);
				}}>
				<svg
					width='14'
					height='9'
					viewBox='0 0 14 9'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M13.6049 5.18512H2.85895L5.63995 7.96612L4.82095 8.78512L0.563945 4.52812L4.82095 0.27112L5.63995 1.09012L2.86795 3.86212H13.6049V5.18512Z'
						fill='#191919'
					/>
				</svg>
				Back
			</button>
			<Typography variant='h1' textAlign={'center'} mb={4}>
				Real Cost Savings Program: Step-by-Step Process
			</Typography>

			<Stack className='steps-w' direction={'row'}>
				<Stack className={`step ${step !== 0 ? 'disabled' : ''}`}>1</Stack>
				<Stack className={`step ${step !== 1 ? 'disabled' : ''}`}>2</Stack>
				<Stack className={`step ${step !== 2 ? 'disabled' : ''}`}>3</Stack>
				<Stack className={`step ${step !== 3 ? 'disabled' : ''}`}>4</Stack>
			</Stack>
			{step === 0 && screen === 0 && <Step1Screen1 />}
			{step === 0 && screen === 1 && <Step1Screen2 />}
			{step === 1 && screen === 0 && <Step2Screen1 />}
			{step === 1 && screen === 1 && <Step2Screen2 />}
			{step === 1 && screen === 2 && <Step2Screen3 />}
			{step === 2 && screen === 0 && <Step3Screen1 />}
			{step === 2 && screen === 1 && <Step3Screen2 />}
			{step === 3 && screen === 0 && <Step4Screen1 />}
			{step === 3 && screen === 1 && <Step4Screen2 />}
		</Stack>
	);
}

export default RCSBrandTracking;
