import React, { useEffect, useState } from 'react';
import { faxTrackingData } from '../../utils/sfFunctions';
import { Button } from '@mui/material';

interface TrackingButtonProps {
	medicationName: string;
	orderTime: string;
	sendFaxTracking: (faxData: faxTrackingData) => Promise<void>;
	faxTrackingMeds: faxTrackingData[];
	onSuccess: (orderTime: string, medicationName: string) => void;
	faxSent: string;
}

const TrackingButton: React.FC<TrackingButtonProps> = ({
	medicationName,
	sendFaxTracking,
	faxTrackingMeds,
	onSuccess,
	orderTime,
	faxSent,
}) => {
	const [buttonText, setButtonText] = useState(
		`Send reminder for ${medicationName}`
	);
	const [isPastThreeWeeks, setIsPastThreeWeeks] = useState(false);
	const [isSentThisWeek, setIsSentThisWeek] = useState(false);

	useEffect(() => {
		const orderDate = new Date(orderTime);
		const faxSentDate = new Date(faxSent);
		const currentDate = new Date();
		const threeWeeksAgo = new Date();
		const weekAgo = new Date();
		threeWeeksAgo.setDate(currentDate.getDate() - 21);
		weekAgo.setDate(currentDate.getDate() - 7);
		setIsPastThreeWeeks(orderDate < threeWeeksAgo);
		setIsSentThisWeek(faxSentDate > weekAgo);
	}, [orderTime, faxSent, isPastThreeWeeks, isSentThisWeek]);

	useEffect(() => {
		if (!isPastThreeWeeks) {
			setButtonText(`Please wait for the order to be 3 weeks old`);
		} else if (isSentThisWeek) {
			setButtonText(`You can only send one reminder per week`);
		} else {
			setButtonText(`Send reminder for ${medicationName}`);
		}
	}, [isPastThreeWeeks, medicationName, isSentThisWeek]);

	return (
		<Button
			variant='contained'
			disabled={
				buttonText ===
					`Error sending reminder for ${medicationName}, please try again later` ||
				!isPastThreeWeeks ||
				isSentThisWeek
			}
			className='cta-main'
			onClick={async () => {
				setButtonText('Sending...');
				const faxData: faxTrackingData = faxTrackingMeds.find(
					(med) => med.Medication_Name__c === medicationName
				)!;
				await sendFaxTracking(faxData)
					.then(() => {
						setButtonText(`Reminder for ${medicationName} was sent`);
						onSuccess(orderTime, medicationName);
					})
					.catch((error) => {
						console.error(error);
						setButtonText(
							`Error sending reminder for ${medicationName}, please try again later`
						);
					});
			}}>
			{buttonText}
		</Button>
	);
};

export default TrackingButton;
