import '../../index.css';
import '../../App.css';
import './Login.css';

import { EmailAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../firebase-setup/firebase';
import StyledFirebaseAuth from './StyledFirbaseAuth';
import { Stack } from '@mui/material';

const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
	signInSuccessUrl: '/',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		GoogleAuthProvider.PROVIDER_ID,
		{
			provider: EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: false,
		},
	],
};

const LoginPage = () => {
	return (
		<>
			<Stack className='login-s'>
				<Stack className='login-c'>
					<StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
				</Stack>
			</Stack>
		</>
	);
};

export default LoginPage;
