import { Button, Drawer, Stack, Typography } from '@mui/material';
import welcomeImg from '../../../assets/images/welcome.webp';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigateWithQuery } from '../../../utils/utilFuntions';
import { useState } from 'react';
function WelcomScreen() {
	const navigate = useNavigate();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const toggleDrawer = (newOpen: boolean) => () => {
		setOpen(newOpen);
	};
	return (
		<>
			<Stack
				justifyContent={{
					xs: 'flex-start',
					md: 'center',
				}}
				sx={{
					backgroundImage: 'linear-gradient( #89aeb765, var(--background) 35%)',
					height: 'calc(100vh - 6.4rem)',
				}}>
				<Stack
					className='container'
					sx={{
						paddingTop: {
							xs: 4,
							md: 8,
						},
					}}>
					<Stack
						spacing={{
							xs: 4,
							md: 12,
						}}
						direction={{
							xs: 'column',
							md: 'row',
						}}
						alignItems={'center'}>
						<Stack
							width={'100%'}
							spacing={{
								xs: 2,
								md: 4,
							}}>
							<Stack spacing={2}>
								<Typography
									variant='h1'
									fontSize={{
										xs: '3rem',
										md: '4rem',
									}}>
									Transparent and Seamless Medication Procurement
								</Typography>
								<Typography variant='h3' fontWeight={400} lineHeight={1.5}>
									We provide efficient access to affordable and accessible
									medications to all Americans who have been prescribed
									medications they simply cannot afford.
								</Typography>
								<Typography variant='h3' fontWeight={400} lineHeight={1.5}>
									We are working directly with you, your healthcare provider,
									and the pharmaceutical manufacturers' pharmacy.
								</Typography>
							</Stack>
							<Stack
								direction={{
									xs: 'column',
									md: 'row',
								}}
								spacing={2}>
								<Button
									variant='contained'
									color='primary'
									onClick={() =>
										navigateWithQuery(navigate, '/enroll', location)
									}>
									Become a Member
								</Button>
								<Button
									variant='outlined'
									color='primary'
									onClick={() => {
										setOpen(true);
									}}>
									Learn More
								</Button>
							</Stack>
						</Stack>
						<Stack
							width={'100%'}
							justifyContent={'center'}
							alignItems={'center'}>
							<img src={welcomeImg} alt='medications' width='500' />
						</Stack>
					</Stack>
				</Stack>
			</Stack>
			<Drawer open={open} onClose={toggleDrawer(false)}>
				<Stack
					justifyContent={'center'}
					alignItems={'end'}
					p={{
						xs: 2,
						md: 6,
					}}
					spacing={2}
					sx={{
						width: {
							sm: '100%',
							md: '60vw',
						},
						position: 'relative',
					}}
					role='presentation'>
					<Button onClick={toggleDrawer(false)} variant='contained'>
						Close
					</Button>
					<Stack>
						<Typography variant='h2'>
							Transparent and Seamless Medication Procurement
						</Typography>
						1. Patient Assistance Program (PAP): This program is designed for
						clients whose insurance plans offer no coverage at all for the
						requested medication. It’s important to note that PAP cannot be used
						when coverage has been denied for any reason; the client must have
						no coverage whatsoever. Each medication has specific income
						eligibility requirements, which is why it’s essential to check every
						case using the Eligibility Calculator we've created for you. 2. Real
						Cost Saving Program: This program is for clients whose insurance
						covers at least part of the medication cost. It’s designed to help
						clients who still find the medication expensive, even with partial
						coverage. The manufacturer steps in to assist, and there is no
						income eligibility requirement for this program. 3. Generic Program:
						For generic medications, we offer the best prices thanks to our
						collaboration with a nonprofit manufacturer. There are no
						eligibility restrictions—clients can have any insurance (including
						Medicare) or any income level and still qualify for this program. 4.
						Medicare Part D Program: For our senior clients, this program helps
						them obtain brand-name medications at no cost. There is no income
						eligibility requirement for this program either, making it easy for
						all Medicare clients to benefit. TransparentpriceRX $200 One-Time
						Annual Enrollment Fee The $200 one-time annual enrollment fee for
						TransparentpriceRX provides members with full access to our
						comprehensive pharmacy benefits program. This fee covers the
						following: Access to Discounted Medications: Members receive
						significantly reduced prices on both brand-name and generic
						medications, ensuring affordability throughout the year. Unlimited
						Medication Requests: This fee covers as many requested medications
						as needed throughout the year, providing peace of mind that you can
						access the medications you require without additional enrollment
						costs. Personalized Medication Management: Our team works directly
						with pharmaceutical manufacturers and healthcare providers to secure
						cost-effective medication solutions tailored to each individual’s
						needs. No Hidden Costs: The one-time fee ensures there are no
						ongoing monthly charges. It covers your enrollment for a full year,
						providing transparent pricing without unexpected costs. Full-Service
						Support: As part of the program, we handle all the necessary
						paperwork, from enrollment to managing patient assistance programs
						and coordinating with your healthcare providers. By paying this fee,
						members gain access to a year of valuable savings and support in
						managing their prescription needs, including an unlimited number of
						medication requests. This enrollment process simplifies the
						complexity of navigating medication costs, giving members peace of
						mind knowing they are getting the best possible prices and support
						throughout the year.
					</Stack>
				</Stack>
			</Drawer>
		</>
	);
}
export default WelcomScreen;
