export interface TPRXPatient {
	fname: string;
	lname: string;
	mname: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	phone: string;
	email: string;
	marital: string;
	residency: boolean;
	employed: string;
	sex: string;
	disabled: boolean;
	numinhouse: string;
	emerContactName: string;
	emerContactPhone: string;
	income: number;
}
export interface TPRXDoctor {
	fname: string;
	mname: string;
	lname: string;
	email: string;
	phone: string;
	fax: string;
	address: string;
	city: string;
	state: string;
	zip: string;
	country: string;
	facility: string;
	fullAddress: string;
}
export interface TPRXOrderItem {
	doctorName: string;
	medicationName: string;
	medicationFrequency: string;
	medicationStrength: string;
	docFax: string;
	docFacility: string;
}
export interface newOrder {
	pateintEmail: string;
	patientSSN: string;
	orderItems: TPRXOrderItem[];
}
export interface faxTrackingData {
	Patient_Name__c: string;
	Patient_Email__c: string;
	Medication_Name__c: string;
	Doctor_Name__c: string;
	Doctor_Fax__c: string;
}

export async function updateClient(data: TPRXPatient) {
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/updatePatient',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const res = await response.json();
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
}

export async function updateDoctor(data: TPRXDoctor) {
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/updateDoc',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const res = await response.json();
		return res;
	} catch (err) {
		console.log(err);
	}
}

export async function createOrder(data: newOrder) {
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/createOrder',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const res = await response.json();
		return res;
	} catch (err) {
		console.log(err);
	}
}

export async function downloadApplications(data: unknown) {
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/fetchApplications',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(data),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.style.display = 'none';
		a.href = url;
		a.download = 'applications.zip';
		document.body.appendChild(a);
		a.click();
		window.URL.revokeObjectURL(url);
	} catch (err) {
		console.log('Error:', err);
	}
}

export async function sendFaxTracking(faxTrackingData: faxTrackingData) {
	try {
		const response = await fetch(
			'https://us-central1-transparent-rx.cloudfunctions.net/sendFaxTracking',
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(faxTrackingData),
			}
		);
		if (!response.ok) {
			throw new Error('Network response was not ok');
		}

		const res = await response.json();
		return res;
	} catch (err) {
		console.log(err);
	}
}
