/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */
import { useContext, useEffect, useRef, useState } from 'react';
import {
	ordersDataState,
	healthcareDataState,
	userDataState,
} from '../../main';
import { useLocation, useNavigate } from 'react-router-dom';
import {
	checkEligibilty,
	defineSelectColor,
	fplCalculator,
	navigateWithQuery,
} from '../../utils/utilFuntions';
import './ComponenetsStyles/Orders.css';
import '../../App.css';
import { DocumentData, addDoc, collection, doc } from 'firebase/firestore';
import { createOrder, newOrder, TPRXOrderItem } from '../../utils/sfFunctions';
import { db } from '../../firebase-setup/firebase';
import ConfirmationIcon from '../../assets/images/ConfirmationIcon.svg';
import emailjs from '@emailjs/browser';
interface GroupedOrders {
	[key: string]: []; // Key is the date as a string
}
function Orders({
	medicationList = [],
}: {
	medicationList: DocumentData[] | null;
}) {
	const navigate = useNavigate();
	const location = useLocation();
	const [isDataChanged, setIsDataChanged] = useState(false);
	const [isSendingOrder, setIsSendingOrder] = useState(false);
	const [isOrderSent, setIsOrderSent] = useState(false);
	const { ordersData } = useContext(ordersDataState);
	const { healthcareData } = useContext(healthcareDataState);
	const { userData } = useContext(userDataState);
	const chosenMedRef = useRef<HTMLSelectElement | null>(null);
	const chosenMedRef2 = useRef<HTMLSelectElement | null>(null);
	const chosenMedRef3 = useRef<HTMLSelectElement | null>(null);
	const chosenMedRef4 = useRef<HTMLSelectElement | null>(null);
	const chosenMedStrengthRef = useRef<HTMLSelectElement | null>(null);
	const chosenMedStrengthRef2 = useRef<HTMLSelectElement | null>(null);
	const chosenMedStrengthRef3 = useRef<HTMLSelectElement | null>(null);
	const chosenMedStrengthRef4 = useRef<HTMLSelectElement | null>(null);
	const [chosenMedPrice, setChosentMedPrice] = useState<string | null>(null);
	const [chosenMedPrice2, setChosentMedPrice2] = useState<string | null>(null);
	const [chosenMedPrice3, setChosentMedPrice3] = useState<string | null>(null);
	const [chosenMedPrice4, setChosentMedPrice4] = useState<string | null>(null);
	const [medicationStrength, setMedicationStrength] = useState<[] | null>(null);
	const [medicationStrength2, setMedicationStrength2] = useState<[] | null>(
		null
	);
	const [medicationStrength3, setMedicationStrength3] = useState<[] | null>(
		null
	);
	const [medicationStrength4, setMedicationStrength4] = useState<[] | null>(
		null
	);
	const [totalPrice, setTotalPrice] = useState<number>(-1);
	const userFpl = useRef<number>(0);

	const form = useRef<HTMLFormElement | null>(null);
	const formMessage = useRef<HTMLTextAreaElement | null>(null);
	const formSubmit = useRef<HTMLButtonElement | null>(null);
	function updateMedicationPrice(
		chosenMedRef: any,
		chosenMedStrengthRef: any,
		setChosentMedPrice: any
	) {
		setChosentMedPrice(
			medicationList!
				.find((med) => med.name === chosenMedRef.current?.value)
				?.options.find(
					(option: any) =>
						option.strength === chosenMedStrengthRef.current!.value
				)!.price
		);
	}
	const newOrder = useRef<newOrder>({
		pateintEmail: '',
		patientSSN: '',
		orderItems: [],
	});
	useEffect(() => {
		if (healthcareData === null) return;
		healthcareData.forEach((doctor, index) => {
			if (doctor.docFname === undefined) return;
			defineSelectColor('chooseDoc-1', healthcareData[index]);
			defineSelectColor('chooseDoc-2', healthcareData[index]);
			defineSelectColor('chooseDoc-3', healthcareData[index]);
			defineSelectColor('chooseDoc-4', healthcareData[index]);
		});
	}, [healthcareData]);
	useEffect(() => {
		defineSelectColor('chooseMed-1');
		defineSelectColor('chooseMed-2');
		defineSelectColor('chooseMed-3');
		defineSelectColor('chooseMed-4');
		defineSelectColor('chooseDoc-1');
		defineSelectColor('chooseDoc-2');
		defineSelectColor('chooseDoc-3');
		defineSelectColor('chooseDoc-4');
	}, []);

	useEffect(() => {
		if (chosenMedStrengthRef.current === null) return;
		if (!medicationStrength) {
			chosenMedStrengthRef.current.style.color = '#a0a0a0';
		} else {
			chosenMedStrengthRef.current.style.color = '#000';
		}
		updateMedicationPrice(
			chosenMedRef,
			chosenMedStrengthRef,
			setChosentMedPrice
		);
	}, [medicationStrength]);

	useEffect(() => {
		if (chosenMedStrengthRef2.current === null) return;
		if (!medicationStrength2) {
			chosenMedStrengthRef2.current.style.color = '#a0a0a0';
		} else {
			chosenMedStrengthRef2.current.style.color = '#000';
		}
		updateMedicationPrice(
			chosenMedRef2,
			chosenMedStrengthRef2,
			setChosentMedPrice2
		);
	}, [medicationStrength2]);

	useEffect(() => {
		if (chosenMedStrengthRef3.current === null) return;
		if (!medicationStrength3) {
			chosenMedStrengthRef3.current.style.color = '#a0a0a0';
		} else {
			chosenMedStrengthRef3.current.style.color = '#000';
		}
		updateMedicationPrice(
			chosenMedRef3,
			chosenMedStrengthRef3,
			setChosentMedPrice3
		);
	}, [medicationStrength3]);

	useEffect(() => {
		if (chosenMedStrengthRef4.current === null) return;
		if (!medicationStrength4) {
			chosenMedStrengthRef4.current.style.color = '#a0a0a0';
		} else {
			chosenMedStrengthRef4.current.style.color = '#000';
		}
		updateMedicationPrice(
			chosenMedRef4,
			chosenMedStrengthRef4,
			setChosentMedPrice4
		);
	}, [medicationStrength4]);

	useEffect(() => {
		if (!userData) return;
		userFpl.current = fplCalculator(userData.numinhouse, userData.income);
	}, [userData]);
	const groupOrdersByDate = (orders: DocumentData[]): GroupedOrders => {
		return orders.reduce((acc, order) => {
			// Format the timestamp as a date string (e.g., '2023-03-25')
			const dateStr = order.timestamp.split('T')[0];

			// If the date key doesn't exist, initialize it with an empty array
			if (!acc[dateStr]) {
				acc[dateStr] = [];
			}
			// Add the order to the appropriate date key
			acc[dateStr].push(order);

			return acc;
		}, {} as GroupedOrders);
	};
	const [groupedOrders, setGroupedOrders] = useState<GroupedOrders>({});
	useEffect(() => {
		if (!ordersData) return;
		const grouped = groupOrdersByDate(ordersData);
		setGroupedOrders(grouped);
	}, []);
	function validateOrder() {
		let allRowsValid = true; // Assume all rows are valid initially
		let allEligible = true;
		// Validate standard input rows
		const rows = document.querySelectorAll(
			'.order-meds-c .inputfields-row:not(.coverage-row)'
		);
		rows.forEach((row) => {
			if (row.classList.contains('hidden')) return; // Skip validation for hidden rows

			const medicationField = row.querySelector(
				`select[name^='chooseMed']`
			) as HTMLSelectElement;
			const frequencyField = row.querySelector(
				`input[name^='frequency']`
			) as HTMLInputElement;
			const docField = row.querySelector(
				`select[name^='chooseDoc']`
			) as HTMLSelectElement;

			let rowValid = true; // Assume the row is valid initially

			// Standard row validation (excluding coverage)
			if (
				medicationField.value === 'default' &&
				frequencyField.value === '' &&
				docField.value === 'default'
			) {
				rowValid = true; // Empty row is considered valid
			} else {
				// Row is valid if all fields are properly filled out
				rowValid =
					medicationField.value !== 'default' &&
					frequencyField.value !== '' &&
					docField.value !== 'default';
				const isEligible = checkEligibilty(
					userFpl.current,
					medicationField.value
				);
				isEligible ? (allEligible = true) : (allEligible = false);
			}

			if (!rowValid) allRowsValid = false; // Mark entire form as invalid if any row is invalid
		});

		// Validate coverage rows separately
		const coverageRows = document.querySelectorAll(
			'.order-meds-c .coverage-row'
		);
		coverageRows.forEach((row) => {
			if (row.classList.contains('hidden')) return; // Skip validation for hidden rows

			const coverageField = row.querySelector(
				`input[name^='med-coverage']`
			) as HTMLInputElement;
			if (!coverageField.checked) {
				allRowsValid = false; // If coverage is required but not checked, mark form as invalid
			}
		});
		if (!allEligible) {
			document.querySelector('.eligible-c')?.classList.remove('hide');
			return;
		} else {
			document.querySelector('.eligible-c')?.classList.add('hide');
		}
		// Update form validity state
		setIsDataChanged(allRowsValid);
	}

	function calculateTotalPrice(
		chosenMedPrice: string | null,
		chosenMedPrice2: string | null,
		chosenMedPrice3: string | null,
		chosenMedPrice4: string | null
	) {
		let total = 0;
		if (chosenMedPrice && chosenMedPrice !== 'No cost')
			total += parseInt(chosenMedPrice.slice(1));
		if (chosenMedPrice2 && chosenMedPrice2 !== 'No cost')
			total += parseInt(chosenMedPrice2.slice(1));
		if (chosenMedPrice3 && chosenMedPrice3 !== 'No cost')
			total += parseInt(chosenMedPrice3.slice(1));
		if (chosenMedPrice4 && chosenMedPrice4 !== 'No cost')
			total += parseInt(chosenMedPrice4.slice(1));
		if (total === -1) return;
		if (total === 0) {
			const pushOrder = async () => {
				await createOrderItems();
				sendOrder();
			};
			pushOrder();
		} else {
			setTotalPrice(total);
			document.querySelector('.order-verification-c')?.classList.add('active');
			document.documentElement.style.overflow = 'hidden';
		}
	}
	async function createOrderItem(medicationRow: HTMLDivElement, i: number) {
		const orderItem: TPRXOrderItem = {
			doctorName: '',
			medicationName: '',
			medicationFrequency: '',
			medicationStrength: '',
			docFax: '',
			docFacility: '',
		};
		const docotorName = medicationRow.querySelector(
			`select[name='chooseDoc-${i}']`
		) as HTMLSelectElement;
		const medicationName = medicationRow.querySelector(
			`select[name='chooseMed-${i}']`
		) as HTMLSelectElement;
		const medicationStrength = medicationRow.querySelector(
			`select[name='chooseStrength-${i}']`
		) as HTMLSelectElement;
		const medicationFrequency = medicationRow.querySelector(
			`input[name='frequency-${i}']`
		) as HTMLInputElement;

		if (
			medicationName.value === 'default' ||
			medicationStrength.value === 'default' ||
			medicationFrequency.value === ''
		)
			return;
		orderItem.doctorName = docotorName.value;
		orderItem.medicationFrequency = medicationFrequency.value;
		orderItem.medicationName = medicationName.value;
		orderItem.medicationStrength = medicationStrength.value;
		orderItem.docFax =
			docotorName.options[docotorName.selectedIndex].dataset.fax!;
		orderItem.docFacility =
			docotorName.options[docotorName.selectedIndex].dataset.facility!;
		newOrder.current.orderItems.push(orderItem);
	}
	async function createOrderItems() {
		const medicationsRows = document.querySelectorAll(
			'.order-meds-c .inputfields-row'
		) as NodeListOf<HTMLDivElement>;
		medicationsRows.forEach(async (medicationRow, i) => {
			await createOrderItem(medicationRow, i + 1);
		});
	}
	async function sendOrder() {
		if (!userData) return;
		setIsSendingOrder(true);
		const userDocRef = doc(db, 'clients', userData.ssn.toString());
		const ordersRef = collection(userDocRef, 'orders');
		for (const orderItem of newOrder.current.orderItems) {
			await addDoc(ordersRef, {
				...orderItem,
				received: false,
				timestamp: new Date().toISOString(),
				strength: medicationList!
					.find((med) => med.name === orderItem.medicationName)
					?.options.find(
						(option: { strength: string }) =>
							option.strength === orderItem.medicationStrength
					)?.strength as string,
			});
		}
		newOrder.current.pateintEmail = userData.email;
		newOrder.current.patientSSN = userData.ssn;

		await createOrder(newOrder.current);

		setIsSendingOrder(false);
		setIsOrderSent(true);
	}

	const sendEmail = () => {
		emailjs
			.sendForm('default_service', 'template_l4g4jzk', form.current!, {
				publicKey: 'RTSZKvi6HCOYvdkil',
			})
			.then(
				() => {},
				(error) => {
					console.log('FAILED...', error.text);
				}
			);
	};

	return (
		<section className='orders-s'>
			<div className='container'>
				<button
					className='back-btn'
					onClick={() => {
						navigateWithQuery(navigate, '/', location);
					}}>
					<svg
						width='14'
						height='9'
						viewBox='0 0 14 9'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'>
						<path
							d='M13.6049 5.18512H2.85895L5.63995 7.96612L4.82095 8.78512L0.563945 4.52812L4.82095 0.27112L5.63995 1.09012L2.86795 3.86212H13.6049V5.18512Z'
							fill='#191919'
						/>
					</svg>
					Back
				</button>
				{isSendingOrder ? (
					<div className='loader-c'>
						<div className='loader' />
					</div>
				) : isOrderSent ? (
					<>
						<div className='success-c'>
							<img src={ConfirmationIcon} alt='success' />
							<h2>Your order has been sent</h2>
						</div>
					</>
				) : (
					<>
						<h1 className='text-center margin-bottom-64'>Orders</h1>

						<div className='orders-info-c'>
							<div className='order-meds-c tprx-card'>
								<h2 className='margin-bottom-32'>Order New Medications</h2>

								<div className='inputfields-row' id='row-1'>
									<div className='inputfield-c'>
										<select
											name='chooseDoc-1'
											id='chooseDoc-1'
											onChange={() => {
												validateOrder();
											}}>
											<option value='default'> Choose Doctor</option>
											{healthcareData?.map((doctor, index) => {
												if (doctor.fname === undefined || doctor.fname === '')
													return;
												return (
													<option
														key={index}
														value={`${doctor.fname} ${doctor.lname}`}
														data-fax={doctor.fax}
														data-facility={doctor.facility}>
														{doctor.fname} {doctor.lname}
													</option>
												);
											})}
										</select>
									</div>
									<div className='inputfield-c'>
										<select
											name='chooseMed-1'
											id='chooseMed-1'
											required
											onChange={(e) => {
												const medication = medicationList!.find(
													(med) => med.name === e.target.value
												);
												validateOrder();
												if (medication === undefined) {
													setMedicationStrength(null);
													return;
												}
												setMedicationStrength(medication.options);
												if (medication.brand) {
													document
														.getElementById('row-2')!
														.classList.remove('hidden');
													(document.getElementById(
														'med-coverage-1'
													) as HTMLInputElement)!.required = true;
												} else {
													document
														.getElementById('row-2')!
														.classList.add('hidden');
													(document.getElementById(
														'med-coverage-1'
													) as HTMLInputElement)!.required = false;
												}
											}}
											ref={chosenMedRef}>
											<option value='default'> Choose Medication</option>
											{medicationList!.map((med, index) => {
												return (
													<option
														key={index}
														value={med.name}
														data-brand={med.brand}>
														{med.name} ({med.genericName})
													</option>
												);
											})}
										</select>
										<div className='error-message'>
											Please fill required field
										</div>
									</div>

									<div className='inputfield-c'>
										<select
											name='chooseStrength-1'
											id='chooseStrength-1'
											required
											ref={chosenMedStrengthRef}
											onChange={() => {
												updateMedicationPrice(
													chosenMedRef,
													chosenMedStrengthRef,
													setChosentMedPrice
												);
												validateOrder();
											}}>
											{medicationStrength ? (
												medicationStrength?.map(
													(
														option: {
															strength: string;
															price: number;
														},
														index
													) => {
														return (
															<option
																key={index}
																value={option.strength}
																data-price={option.price}>
																{option.strength}
															</option>
														);
													}
												)
											) : (
												<option value='default'>Choose Medication First</option>
											)}
										</select>
										<div className='error-message'>
											Please fill required field
										</div>
									</div>
									<div className='inputfield-c'>
										<input
											type='input'
											required
											name='frequency-1'
											id='frequency-1'
											placeholder='Frequency '
											onChange={() => {
												validateOrder();
											}}
										/>
										<div className='error-message'>
											Please fill required field
										</div>
									</div>
									<div className='medication-price-c'>
										<div className='medication-price'>
											{chosenMedPrice
												? `${
														chosenMedPrice === 'No cost'
															? 'No cost'
															: 'Price:' + chosenMedPrice + '(90 day supply)'
												  }`
												: 'No cost'}
										</div>
									</div>
								</div>
								<div className='coverage-row hidden' id='row-2'>
									<div className='coverage-checkbox-c'>
										<input
											type='checkbox'
											name='med-coverage-1'
											id='med-coverage-1'
											onChange={() => {
												validateOrder();
											}}
										/>
										<label htmlFor='med-coverage-1'>
											No pharmacy coverage is available for the medication I
											need, either through my plan or otherwise.
										</label>
									</div>
									<div className='error-message'>
										Please fill required field
									</div>
								</div>
								<div className='inputfields-row' id='row-3'>
									<div className='inputfield-c'>
										<select
											name='chooseDoc-2'
											id='chooseDoc-2'
											onChange={() => {
												validateOrder();
											}}>
											<option value='default'> Choose Doctor</option>
											{healthcareData?.map((doctor, index) => {
												if (doctor.fname === undefined || doctor.fname === '')
													return;
												return (
													<option
														key={index}
														value={`${doctor.fname} ${doctor.lname}`}
														data-fax={doctor.fax}
														data-facility={doctor.facility}>
														{doctor.fname} {doctor.lname}
													</option>
												);
											})}
										</select>
									</div>
									<div className='inputfield-c'>
										<select
											name='chooseMed-2'
											id='chooseMed-2'
											onChange={(e) => {
												const medication = medicationList!.find(
													(med) => med.name === e.target.value
												);
												validateOrder();
												validateOrder();

												if (medication === undefined) {
													setMedicationStrength2(null);
													return;
												}
												if (medication.brand) {
													document
														.getElementById('row-4')!
														.classList.remove('hidden');
													(document.getElementById(
														'med-coverage-2'
													) as HTMLInputElement)!.required = true;
												} else {
													document
														.getElementById('row-4')!
														.classList.add('hidden');
													(document.getElementById(
														'med-coverage-2'
													) as HTMLInputElement)!.required = false;
												}
												setMedicationStrength2(medication.options);
											}}
											ref={chosenMedRef2}>
											<option value='default'> Choose Medication</option>
											{medicationList!.map((med, index) => {
												return (
													<option key={index} value={med.name}>
														{med.name}
													</option>
												);
											})}
										</select>
									</div>

									<div className='inputfield-c'>
										<select
											name='chooseStrength-2'
											id='chooseStrength-2'
											ref={chosenMedStrengthRef2}
											onChange={() => {
												updateMedicationPrice(
													chosenMedRef2,
													chosenMedStrengthRef2,
													setChosentMedPrice2
												);
												validateOrder();
											}}>
											{medicationStrength2 ? (
												medicationStrength2?.map(
													(
														option: {
															strength: string;
															price: number;
														},
														index
													) => {
														return (
															<option key={index} value={option.strength}>
																{option.strength}
															</option>
														);
													}
												)
											) : (
												<option value='default'>
													{' '}
													Choose Medication First
												</option>
											)}
										</select>
									</div>
									<div className='inputfield-c'>
										<input
											type='input'
											name='frequency-2'
											id='frequency-2'
											placeholder='Frequency'
											onChange={() => {
												validateOrder();
											}}
										/>
									</div>
									<div className='medication-price-c'>
										<div className='medication-price'>
											{chosenMedPrice2
												? `${
														chosenMedPrice2 === 'No cost'
															? 'No cost'
															: 'Price:' + chosenMedPrice2 + '(90 day supply)'
												  }`
												: 'No cost'}
										</div>
									</div>
								</div>
								<div className='coverage-row hidden' id='row-4'>
									<div className='coverage-checkbox-c'>
										<input
											type='checkbox'
											name='med-coverage-2'
											id='med-coverage-2'
											onChange={() => {
												validateOrder();
											}}
										/>
										<label htmlFor='med-coverage-2'>
											No pharmacy coverage is available for the medication I
											need, either through my plan or otherwise.
										</label>
									</div>
									<div className='error-message'>
										Please fill required field
									</div>
								</div>
								<div className='inputfields-row' id='row-5'>
									<div className='inputfield-c'>
										<select
											name='chooseDoc-3'
											id='chooseDoc-3'
											onChange={() => {
												validateOrder();
											}}>
											<option value='default'> Choose Doctor</option>
											{healthcareData?.map((doctor, index) => {
												if (doctor.fname === undefined || doctor.fname === '')
													return;
												return (
													<option
														key={index}
														value={`${doctor.fname} ${doctor.lname}`}
														data-fax={doctor.fax}
														data-facility={doctor.facility}>
														{doctor.fname} {doctor.lname}
													</option>
												);
											})}
										</select>
									</div>
									<div className='inputfield-c'>
										<select
											name='chooseMed-3'
											id='chooseMed-3'
											onChange={(e) => {
												const medication = medicationList!.find(
													(med) => med.name === e.target.value
												);
												validateOrder();

												if (medication === undefined) {
													setMedicationStrength3(null);
													return;
												}
												if (medication.brand) {
													document
														.getElementById('row-6')!
														.classList.remove('hidden');
													(document.getElementById(
														'med-coverage-3'
													) as HTMLInputElement)!.required = true;
												} else {
													document
														.getElementById('row-6')!
														.classList.add('hidden');
													(document.getElementById(
														'med-coverage-3'
													) as HTMLInputElement)!.required = false;
												}
												setMedicationStrength3(medication.options);
											}}
											ref={chosenMedRef3}>
											<option value='default'> Choose Medication</option>
											{medicationList!.map((med, index) => {
												return (
													<option key={index} value={med.name}>
														{med.name}
													</option>
												);
											})}
										</select>
									</div>

									<div className='inputfield-c'>
										<select
											name='chooseStrength-3'
											id='chooseStrength-3'
											ref={chosenMedStrengthRef3}
											onChange={() => {
												updateMedicationPrice(
													chosenMedRef3,
													chosenMedStrengthRef3,
													setChosentMedPrice3
												);
												validateOrder();
											}}>
											{medicationStrength3 ? (
												medicationStrength3?.map(
													(
														option: {
															strength: string;
															price: number;
														},
														index
													) => {
														return (
															<option key={index} value={option.strength}>
																{option.strength}
															</option>
														);
													}
												)
											) : (
												<option value='default'>
													{' '}
													Choose Medication First
												</option>
											)}
										</select>
									</div>
									<div className='inputfield-c'>
										<input
											type='input'
											name='frequency-3'
											id='frequency-3'
											placeholder='Frequency'
											onChange={() => {
												validateOrder();
											}}
										/>
									</div>
									<div className='medication-price-c'>
										<div className='medication-price'>
											{chosenMedPrice3
												? `${
														chosenMedPrice3 === 'No cost'
															? 'No cost'
															: 'Price:' + chosenMedPrice3 + '(90 day supply)'
												  }`
												: 'No cost'}
										</div>
									</div>
								</div>
								<div className='coverage-row hidden' id='row-6'>
									<div className='coverage-checkbox-c'>
										<input
											type='checkbox'
											name='med-coverage-3'
											id='med-coverage-3'
											onChange={() => {
												validateOrder();
											}}
										/>
										<label htmlFor='med-coverage-3'>
											No pharmacy coverage is available for the medication I
											need, either through my plan or otherwise.
										</label>
									</div>
									<div className='error-message'>
										Please fill required field
									</div>
								</div>
								<div className='inputfields-row' id='row-7'>
									<div className='inputfield-c'>
										<select
											name='chooseDoc-4'
											id='chooseDoc-4'
											onChange={() => {
												validateOrder();
											}}>
											<option value='default'> Choose Doctor</option>
											{healthcareData?.map((doctor, index) => {
												if (doctor.fname === undefined || doctor.fname === '')
													return;
												return (
													<option
														key={index}
														value={`${doctor.fname} ${doctor.lname}`}
														data-fax={doctor.fax}
														data-facility={doctor.facility}>
														{doctor.fname} {doctor.lname}
													</option>
												);
											})}
										</select>
									</div>
									<div className='inputfield-c'>
										<select
											name='chooseMed-4'
											id='chooseMed-4'
											onChange={(e) => {
												const medication = medicationList!.find(
													(med) => med.name === e.target.value
												);
												validateOrder();

												if (medication === undefined) {
													setMedicationStrength4(null);
													return;
												}
												if (medication.brand) {
													document
														.getElementById('row-8')!
														.classList.remove('hidden');
													(document.getElementById(
														'med-coverage-4'
													) as HTMLInputElement)!.required = true;
												} else {
													document
														.getElementById('row-8')!
														.classList.add('hidden');
													(document.getElementById(
														'med-coverage-4'
													) as HTMLInputElement)!.required = false;
												}
												setMedicationStrength4(medication.options);
											}}
											ref={chosenMedRef4}>
											<option value='default'> Choose Medication</option>
											{medicationList!.map((med, index) => {
												return (
													<option key={index} value={med.name}>
														{med.name}
													</option>
												);
											})}
										</select>
									</div>

									<div className='inputfield-c'>
										<select
											name='chooseStrength-4'
											id='chooseStrength-4'
											ref={chosenMedStrengthRef4}
											onChange={() => {
												updateMedicationPrice(
													chosenMedRef4,
													chosenMedStrengthRef4,
													setChosentMedPrice4
												);
												validateOrder();
											}}>
											{medicationStrength4 ? (
												medicationStrength4?.map(
													(
														option: {
															strength: string;
															price: number;
														},
														index
													) => {
														return (
															<option key={index} value={option.strength}>
																{option.strength}
															</option>
														);
													}
												)
											) : (
												<option value='default'>
													{' '}
													Choose Medication First
												</option>
											)}
										</select>
									</div>
									<div className='inputfield-c'>
										<input
											type='input'
											name='frequency-4'
											id='frequency-4'
											placeholder='Frequency '
											onChange={() => {
												validateOrder();
											}}
										/>
									</div>
									<div className='medication-price-c'>
										<div className='medication-price'>
											{chosenMedPrice4
												? `${
														chosenMedPrice4 === 'No cost'
															? 'No cost'
															: 'Price:' + chosenMedPrice4 + '(90 day supply)'
												  }`
												: 'No cost'}
										</div>
									</div>
								</div>
								<div className='coverage-row hidden' id='row-8'>
									<div className='coverage-checkbox-c'>
										<input
											type='checkbox'
											name='med-coverage-4'
											id='med-coverage-4'
											onChange={() => {
												validateOrder();
											}}
										/>
										<label htmlFor='med-coverage-4'>
											No pharmacy coverage is available for the medication I
											need, either through my plan or otherwise.
										</label>
									</div>
									<div className='error-message'>
										Please fill required field
									</div>
								</div>
								<button
									className='cta-main desktop-only'
									disabled={!isDataChanged}
									onClick={async () => {
										calculateTotalPrice(
											chosenMedPrice,
											chosenMedPrice2,
											chosenMedPrice3,
											chosenMedPrice4
										);
									}}>
									Order Now
								</button>
								<div className='eligible-c hide'>
									<div className='eligible-w'>
										<h3>
											Sorry, you are not eligible to apply for this medication
										</h3>
										<p>
											Please check that your income is correct, and if you take
											care of your parents or children, count them in the number
											on household, this numbers affect the eligibility criteria
										</p>
									</div>
								</div>
							</div>

							<div className='last-order-c tprx-card'>
								<h2 className='margin-bottom-32'>My Orders</h2>
								<div className='orders-list-dates'>
									<div className='orders-list'>
										{Object.entries(groupedOrders).map(([date, orders]) => {
											return (
												<div className='orders-list-c' key={date}>
													<h3 className='order-date-c'>{date}</h3>
													{orders.map((order: DocumentData, index) => {
														return (
															<div className='order' key={index}>
																<div className='order-info-row'>
																	<p className='order-info-text '>
																		{order.medicationName}
																	</p>
																	<p className='order-info-text small-text'>
																		{order.medicationStrength}
																	</p>
																</div>
															</div>
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
							</div>
						</div>
					</>
				)}
			</div>

			<div className='save-btn-c order-btn'>
				<button
					className='cta-main'
					disabled={!isDataChanged}
					onClick={async () => {
						calculateTotalPrice(
							chosenMedPrice,
							chosenMedPrice2,
							chosenMedPrice3,
							chosenMedPrice4
						);
					}}>
					Order Now
				</button>
			</div>
			<div className='order-verification-c'>
				<div className='tprx-card'>
					<h2>Order Confirmation</h2>
					<div>
						<h3 className='text-center'>
							<strong>Your total order cost is: ${totalPrice}</strong>
						</h3>
						<p>You will have to pay this amount to the manufacturer</p>
					</div>
					<div className='order-verification-btn-c'>
						<button
							className='cta-main'
							onClick={async () => {
								await createOrderItems();
								sendOrder();
								document
									.querySelector('.order-verification-c')
									?.classList.remove('active');
								formMessage.current!.innerHTML = `Name: ${
									userData!.fname +
									' ' +
									userData!.mname +
									' ' +
									userData!.lname
								},
								Email: ${userData!.email},
								Order: ${newOrder.current.orderItems
									.map((item) => {
										return `Doctor: ${item.doctorName}, Medication: ${item.medicationName}, Strength: ${item.medicationStrength}, Frequency: ${item.medicationFrequency}`;
									})
									.join(', ')}`;
								formSubmit.current!.click();
							}}>
							Confirm Order
						</button>
						<button
							className='cta-main secondary-btn'
							onClick={async () => {
								document
									.querySelector('.order-verification-c')
									?.classList.remove('active');
								document.documentElement.style.overflow = 'unset';
							}}>
							Cancel Order
						</button>
					</div>
				</div>
			</div>

			<form
				className='hidden'
				ref={form}
				onSubmit={(e) => {
					e.preventDefault();
					sendEmail();
				}}>
				<label>Message</label>
				<textarea name='message' ref={formMessage} />
				<button ref={formSubmit} type='submit' />
			</form>
		</section>
	);
}

export default Orders;
