import {
	Checkbox,
	Divider,
	FormControlLabel,
	FormGroup,
	Stack,
	Typography,
} from '@mui/material';
import { formValues } from '../../utils/types';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

function ValidationScreen({
	values,
	setValues,
	setValidStep,
}: {
	values: formValues;
	setValues: React.Dispatch<React.SetStateAction<formValues>>;
	setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [checkMarks, setCheckMarks] = useState({
		informationStatement: false,
		policiesStatement: false,
		incomeStatement: false,
		effectiveStatement: false,
	});

	useEffect(() => {
		if (
			checkMarks.incomeStatement &&
			checkMarks.informationStatement &&
			checkMarks.policiesStatement &&
			checkMarks.effectiveStatement
		) {
			setValidStep(true);
		} else {
			setValidStep(false);
		}
	}, [checkMarks, setValidStep, values]);

	return (
		<Stack alignItems={'center'}>
			<Stack
				spacing={8}
				alignItems={'stretch'}
				sx={{
					width: {
						xs: '100%',
						md: '80rem',
					},
				}}>
				<Typography variant='h1' textAlign={'center'}>
					Verify your details
				</Typography>
				<Stack className='tprx-card' alignItems={'stretch'}>
					<Stack spacing={1} textAlign={'left'}>
						<Typography variant='h2'>Personal Details</Typography>
						<Divider />
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Name:</span>{' '}
							{values.patient.fname} {values.patient.mname}{' '}
							{values.patient.lname}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Birthday:</span>
							{values.patient.dob}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Gender:</span>{' '}
							{values.patient.sex}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>SSN:</span> {values.patient.ssn}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Marital Status:</span>
							{values.patient.marital}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Employment Status:</span>
							{values.patient.employed}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Person of disability:</span>
							{values.patient.disabled ? 'Yes' : 'No'}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Annual Income:</span>
							{values.patient.income}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Email:</span>
							{values.patient.email}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Phone Number:</span>
							{values.patient.phone}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>
								Number of people in household:
							</span>
							{values.patient.numinhouse}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Address:</span>
							{values.patient.apt}, {values.patient.address},{' '}
							{values.patient.zip}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Alternate contact:</span>
							{values.patient.emerContactName}
						</Typography>
						<Typography variant='body1'>
							<span style={{ fontWeight: 600 }}>Alternate contact phone:</span>
							{values.patient.emerContactPhone}
						</Typography>
					</Stack>
					<Stack spacing={1} textAlign={'left'}>
						<Typography variant='h2'>Healthcare providers Details</Typography>
						{values.doctors.map((doc, index) => (
							<Stack spacing={1} textAlign={'left'} key={doc.fname + index}>
								<Divider />
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Name:</span> {doc.fname}{' '}
									{doc.mname} {doc.lname}
								</Typography>
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Email:</span> {doc.email}
								</Typography>
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Phone Number:</span>
									{doc.phone}
								</Typography>
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Fax Number:</span>
									{doc.fax}
								</Typography>
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Address:</span>
									{doc.address}, {doc.suite}, {doc.city}, {doc.state}, {doc.zip}
								</Typography>
								<Typography variant='body1'>
									<span style={{ fontWeight: 600 }}>Facility:</span>
									{doc.facility}
								</Typography>
							</Stack>
						))}
					</Stack>
					<Stack spacing={1} textAlign={'left'}>
						<Typography variant='h2'>Medications</Typography>
						{values.orders.map(
							(order, index) =>
								order.medicationName !== '' && (
									<Stack
										spacing={1}
										textAlign={'left'}
										key={order.medicationName + index}>
										<Divider />
										<Typography variant='body1'>
											<span style={{ fontWeight: 600 }}>Medication Name:</span>{' '}
											{order.medicationName}
										</Typography>
										<Typography variant='body1'>
											<span style={{ fontWeight: 600 }}>
												Medication Strength:
											</span>{' '}
											{order.medicationStrength}
										</Typography>
										<Typography variant='body1'>
											<span style={{ fontWeight: 600 }}>
												Prescribing Healthcare Provider:
											</span>
											{order.doctorName}
										</Typography>
									</Stack>
								)
						)}
					</Stack>
				</Stack>
				<Stack
					className='tprx-card'
					textAlign={'left'}
					alignItems={'flex-start'}>
					<Typography variant='h2'>Summary</Typography>
					<Divider sx={{ borderWidth: 1, width: '100%' }} />
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									onChange={(e) => {
										values.agreements.incomeStatement = e.target.checked;
										setCheckMarks((prev) => ({
											...prev,
											incomeStatement: e.target.checked,
										}));
										setValues((prev) => ({
											...prev,
											agreements: {
												...prev.agreements,
												incomeStatement: e.target.checked,
											},
										}));
									}}
								/>
							}
							label='I confirm that my stated income accurately reflects my current total income. I acknowledge that income verification is a mandatory requirement by the pharmaceutical manufacturer'
							required
						/>
						<FormControlLabel
							required
							control={
								<Checkbox
									onChange={(e) => {
										values.agreements.informationStatement = e.target.checked;
										setCheckMarks((prev) => ({
											...prev,
											informationStatement: e.target.checked,
										}));
										setValues((prev) => ({
											...prev,
											agreements: {
												...prev.agreements,
												informationStatement: e.target.checked,
											},
										}));
									}}
								/>
							}
							label='I have thoroughly read and understood all the information provided.'
						/>
						<FormControlLabel
							required
							control={
								<Checkbox
									onChange={(e) => {
										values.agreements.policiesStatement = e.target.checked;
										setCheckMarks((prev) => ({
											...prev,
											policiesStatement: e.target.checked,
										}));
										setValues((prev) => ({
											...prev,
											agreements: {
												...prev.agreements,
												policiesStatement: e.target.checked,
											},
										}));
									}}
								/>
							}
							label={`I understand that I’ll be able to start using this program
										at your pharmacy of choice when my health insurance becomes
										effective.`}
						/>
						<FormControlLabel
							required
							control={
								<Checkbox
									onChange={(e) => {
										values.agreements.effectiveStatement = e.target.checked;
										setCheckMarks((prev) => ({
											...prev,
											effectiveStatement: e.target.checked,
										}));
										setValues((prev) => ({
											...prev,
											agreements: {
												...prev.agreements,
												effectiveStatement: e.target.checked,
											},
										}));
									}}
								/>
							}
							label={
								<>
									<span>I acknowledge the </span>
									<Link
										target='_blank'
										to='https://www.transparentpricerx.com/privacy-policy'
										style={{ cursor: 'pointer', textDecoration: 'underline' }}>
										Privacy Policy
									</Link>
									<span> and the</span>
									<Link
										target='_blank'
										to='https://www.transparentpricerx.com/refund-policy'
										style={{ cursor: 'pointer', textDecoration: 'underline' }}>
										{' '}
										Refund Policy.
									</Link>
									<span>
										I understand that my consent is required to proceed.
									</span>
								</>
							}
						/>
					</FormGroup>
				</Stack>
			</Stack>
		</Stack>
	);
}
export default ValidationScreen;
