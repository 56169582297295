/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Button,
	Dialog,
	DialogContent,
	Divider,
	Stack,
	Typography,
} from '@mui/material';

function CostDialog({
	open,
	handleClose,
	medicationData,
}: {
	open: boolean;
	handleClose: () => void;
	medicationData: any;
}) {
	const prices = medicationData.prices;
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'sm'}
			PaperProps={{ sx: { borderRadius: 4 } }}>
			<DialogContent>
				<Stack spacing={4} p={2} key={medicationData.name}>
					{prices.map((price: any) => (
						<>
							<Stack spacing={1}>
								<Typography variant='body1' fontWeight={600}>
									{price.price}
								</Typography>
								<Divider />
								<Typography variant='body1'>{price.name}</Typography>
							</Stack>
						</>
					))}
					<Stack
						spacing={1}
						p={2}
						borderRadius={2}
						bgcolor={'#153B61'}
						color={'white'}
						textAlign={'center'}>
						<Typography variant='body1' fontWeight={600}>
							Disclaimer
						</Typography>
						<Divider sx={{ borderColor: 'white' }} />
						<Typography variant='body1'>
							This estimation is based on the insurance product you have. The
							final price might change based on the amount of medication
							ordered, your pharmacy, and your insurance.
						</Typography>
					</Stack>
					<Button onClick={handleClose} variant='contained' color='primary'>
						I Understand
					</Button>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}
export default CostDialog;
