/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	Autocomplete,
	Box,
	Button,
	Grid,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import formConfig from './formConfig';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { docInfo, formConfigField } from '../../utils/types';
import AutocompleteField from './AutoCompleteField';
import { formatPhoneNumber } from '../../utils/utilFuntions';

function ForthStep({
	numDocs,
	setNumDocs,
	values,
	setValues,
	setValidStep,
}: {
	numDocs: number;
	setNumDocs: React.Dispatch<React.SetStateAction<number>>;
	values: any;
	setValues: React.Dispatch<React.SetStateAction<any>>;
	setValidStep: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const [errors, setErrors] = useState<{ [key: string]: string }>({});
	const availableMedsforOrder = Array.from(
		values.orders,
		(order: any) => order.medicationName
	).filter((medName: string) => medName !== '') as string[];

	const [selectedMeds, setSelectedMeds] = useState<string[][]>(
		Array(numDocs).fill([])
	);
	// Centralized validation function
	const validateFields = useCallback(
		(name: string, value: string, object: docInfo) => {
			let error = '';
			if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
				error = 'Invalid email address';
			} else if (name === 'phone' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) {
				error = 'Invalid phone number';
			} else if (name === 'fax' && !/^\(\d{3}\) \d{3} - \d{4}$/.test(value)) {
				error = 'Invalid fax number';
			} else if (name === 'faxValidate' && value !== object.fax) {
				error = 'Fax number does not match';
			} else if (name === 'zip' && !/^\d{5}$/.test(value)) {
				error = 'Invalid Zip Code';
			} else if (name === 'address' && value === '') {
				error = 'Invalid Address';
			} else if (
				formConfig[3].fields.find((field) => field.name === name)?.required
			) {
				if (value === '') {
					error = 'This field is required';
				}
			}
			return error;
		},
		[]
	);

	const handleChangeWithValidation = (
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		const fieldName = event.target.name;
		const fieldValue = event.target.value;

		// Perform validation
		const error = validateFields(fieldName, fieldValue, values.doctors[index]);
		setErrors((prevErrors) => ({
			...prevErrors,
			[fieldName + index]: error,
		}));

		// Update the specific doctor's field while preserving other values
		setValues((prevValues: any) => ({
			...prevValues,
			doctors: prevValues.doctors.map((doc: docInfo, docIndex: number) =>
				docIndex === index
					? {
							...doc, // Preserve other fields
							[fieldName]: fieldValue, // Update the specific field
					  }
					: doc
			),
		}));
	};

	// Handle updating the selected medications and syncing with orders
	const handleAutocompleteChange = async (index: number, value: any) => {
		const otherSelectedMeds = selectedMeds.flatMap((meds, idx) =>
			idx !== index ? meds : []
		);
		const allSelectedMeds = [...otherSelectedMeds, ...value];
		const newSelectedMeds = [...selectedMeds];
		newSelectedMeds[index] = value;

		const docName =
			values.doctors[index].mname === ''
				? `${values.doctors[index].fname} ${values.doctors[index].lname}`
				: `${values.doctors[index].fname} ${values.doctors[index].mname} ${values.doctors[index].lname}`;

		if (value.length === 0) {
			await updateOrderData('', docName);
		} else {
			value.forEach(async (medName: string) => {
				await updateOrderData(medName as unknown as string, docName);
			});
		}

		setSelectedMeds(newSelectedMeds);
		checkAllMedsSelected(allSelectedMeds);
	};

	// Check if all medications are selected for all doctors
	const checkAllMedsSelected = (allSelectedMeds: string[]) => {
		if (allSelectedMeds.length !== availableMedsforOrder.length) {
			for (let i = 0; i < numDocs; i++) {
				setErrors((prevErrors) => ({
					...prevErrors,
					['prescribedMed' + i]: 'Please select all prescribed medications',
				}));
			}
		} else {
			for (let i = 0; i < numDocs; i++) {
				setErrors((prevErrors) => ({
					...prevErrors,
					['prescribedMed' + i]: '',
				}));
			}
		}
	};

	// Update the order with doctor information
	async function updateOrderData(medName: string, docName: string) {
		values.orders.forEach((order: any) => {
			if (order.medicationName === medName) {
				setValues((prevValues: any) => ({
					...prevValues,
					orders: prevValues.orders.map((ord: any) =>
						ord.medicationName === medName
							? { ...ord, doctorName: docName }
							: ord
					),
				}));
			}
			if (medName === '') {
				setValues((prevValues: any) => ({
					...prevValues,
					orders: prevValues.orders.map((ord: any) =>
						ord.medicationName === medName ? { ...ord, doctorName: '' } : ord
					),
				}));
			}
		});
	}

	// Format ZIP code
	function formatZip(value: string) {
		return value.replace(/\D/g, '').substring(0, 5);
	}

	// Filter options for medications excluding already selected ones
	const getFilteredOptions = (index: number) => {
		const otherSelectedMeds = selectedMeds.flatMap((meds, idx) =>
			idx !== index ? meds : []
		);
		return availableMedsforOrder.filter(
			(option) => !otherSelectedMeds.includes(option)
		);
	};

	// Validate if all required fields are filled
	const areAllRequiredFieldsFilled = useCallback(
		(doctors: docInfo[]) => {
			const requiredFields = [
				'fname',
				'lname',
				'facility',
				'phone',
				'fullAddress',
				'zip',
				'fax',
				'faxValidate',
			];
			let valid = true;

			doctors.forEach((doctor: docInfo) => {
				const areDoctorFieldsFilled = requiredFields.every(
					(field) => !!doctor[field as keyof docInfo]
				);
				const docName =
					doctor.mname === ''
						? `${doctor.fname} ${doctor.lname}`
						: `${doctor.fname} ${doctor.mname} ${doctor.lname}`;
				const areOrdersFilled = values.orders
					.filter((order: any) => order.medicationName !== '')
					.some((order: any) => order.doctorName === docName);

				if (!areDoctorFieldsFilled || !areOrdersFilled) {
					valid = false;
				}
			});
			const hasErrors = Object.values(errors).some((err) => err !== '');
			return valid && !hasErrors;
		},
		[errors, values.orders]
	);

	// Run validation whenever doctors or errors change
	useEffect(() => {
		setValidStep(areAllRequiredFieldsFilled(values.doctors));
	}, [areAllRequiredFieldsFilled, errors, setValidStep, values.doctors]);

	return (
		<>
			<Typography variant='h2' textAlign={'center'}>
				{formConfig[3].title}
			</Typography>
			{numDocs > 0 &&
				Array.from({ length: numDocs }, (_, index) => (
					<React.Fragment key={index}>
						<Box className='tprx-card'>
							<Grid container spacing={2}>
								{formConfig[3].fields.map((field: formConfigField) => (
									<Grid
										item
										xs={12}
										sm={field.name === 'prescribedMed' ? 12 : 4}
										key={field.name}>
										{(() => {
											switch (field.type) {
												case 'select':
													return (
														<Autocomplete
															options={getFilteredOptions(index)}
															onChange={(_e, value) => {
																handleAutocompleteChange(index, value);
															}}
															multiple
															renderInput={(params) => (
																<TextField
																	{...params}
																	required
																	fullWidth
																	name={field.name}
																	value={
																		values.orders[index]['medicationName'] || ''
																	}
																	label={field.label}
																	variant='outlined'
																	error={!!errors[field.name + index]}
																	helperText={errors[field.name + index]}
																/>
															)}
														/>
													);
												case 'address':
													return (
														<Grid item xs={12} sm={12} key={field.name}>
															<AutocompleteField
																label={field.label}
																name={field.name}
																value={
																	values.doctors[index]['fullAddress'] || ''
																}
																onChange={(addressValues) => {
																	Object.keys(addressValues).forEach((name) => {
																		const value =
																			addressValues[name as keyof docInfo];
																		handleChangeWithValidation(
																			{
																				target: { name, value },
																			} as unknown as React.ChangeEvent<HTMLInputElement>,
																			index
																		);
																	});
																}}
																errors={errors['fullAddress' + index]}
																setErrors={setErrors}
																errorObjectName={'fullAddress' + index}
															/>
														</Grid>
													);
												default:
													return (
														<TextField
															error={!!errors[field.name + index]}
															helperText={errors[field.name + index]}
															required={field.required}
															label={field.label}
															name={field.name}
															type={field.type}
															value={values.doctors[index][field.name] || ''}
															onChange={(e: ChangeEvent<HTMLInputElement>) => {
																if (
																	field.name === 'phone' ||
																	field.name === 'fax' ||
																	field.name === 'faxValidate'
																) {
																	formatPhoneNumber(e);
																} else if (field.name === 'zip') {
																	e.target.value = formatZip(e.target.value);
																}
																handleChangeWithValidation(e, index);
															}}
															margin='normal'
															fullWidth
														/>
													);
											}
										})()}
									</Grid>
								))}
								{index >= 1 && (
									<Grid item xs={12}>
										<Stack
											direction='row'
											spacing={2}
											justifyContent={'flex-end'}>
											<Button
												onClick={() => {
													setNumDocs(numDocs - 1);
													values.doctors.splice(index, 1);
													setValidStep(
														areAllRequiredFieldsFilled(values.doctors)
													);
												}}
												variant='contained'>
												Remove
											</Button>
										</Stack>
									</Grid>
								)}
							</Grid>
						</Box>
					</React.Fragment>
				))}
			<Stack direction='row' spacing={2}>
				<Button
					onClick={() => {
						setNumDocs(numDocs + 1);
						values.doctors.push({
							fname: '',
							mname: '',
							lname: '',
							email: '',
							phone: '',
							fax: '',
							address: '',
							city: '',
							state: '',
							zip: '',
							country: '',
							facility: '',
							suite: '',
						});
						setValidStep(false);
					}}
					variant='outlined'>
					I have another healthcare provider
				</Button>
			</Stack>
		</>
	);
}

export default ForthStep;
