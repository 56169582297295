/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentData } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useLocation } from 'react-router-dom';

export function handleGoogleMapsChange(
	e: string,
	userData: DocumentData | null,
	setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>,
	fieldName: string
) {
	const value = e;
	if (value === '') {
		setIsDataChanged(false);
		return;
	}
	if (value !== userData![fieldName as keyof typeof userData]) {
		setIsDataChanged(true);
	} else {
		setIsDataChanged(false);
	}
}

export function handleInputchange(
	e: React.ChangeEvent<HTMLInputElement>,
	userData: DocumentData | null,
	setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>,
	isValid?: boolean
) {
	const value = e.target.value;
	if (!isValid) {
		if (
			value !== userData![e.target.name as keyof typeof userData].toString() &&
			value !== ''
		) {
			setIsDataChanged(true);
		} else {
			setIsDataChanged(false);
		}
	} else {
		if (
			value !== userData![e.target.name as keyof typeof userData] &&
			value !== '' &&
			isValid
		) {
			setIsDataChanged(true);
		} else {
			setIsDataChanged(false);
		}
	}
}
export function handleSelectChange(
	e: React.ChangeEvent<HTMLSelectElement>,
	userData: DocumentData | null,
	setIsDataChanged: React.Dispatch<React.SetStateAction<boolean>>
) {
	if (
		e.target.value !== userData![e.target.name as keyof typeof userData] &&
		e.target.value !== ''
	) {
		setIsDataChanged(true);
	} else {
		setIsDataChanged(false);
	}
}

export function defineSelectColor(
	userDataKey: string,
	userData?: DocumentData
) {
	const selectField = document.querySelector(
		`select[name=${userDataKey}]`
	) as HTMLSelectElement;

	if (!userData) {
		if (selectField.value !== 'default') {
			selectField.style.color = '#000';
		}
		selectField.addEventListener('change', function () {
			if (selectField.value !== 'default') {
				selectField.style.color = '#000';
			} else {
				selectField.style.color = '#a0a0a0';
			}
		});
		return;
	}
	selectField.querySelectorAll('option').forEach((option) => {
		if (option.value === userData![userDataKey]) {
			option.selected = true;
		}
	});
	if (
		selectField.value !== '' &&
		selectField.value !== userData![userDataKey]
	) {
		selectField.style.color = '#000';
	}
	selectField.addEventListener('change', function () {
		if (
			selectField.value !== '' &&
			selectField.value !== userData![userDataKey]
		) {
			selectField.style.color = '#000';
		} else {
			selectField.style.color = '#a0a0a0';
		}
	});
}

export function formatPhoneNumber(event: React.ChangeEvent<HTMLInputElement>) {
	event.target.value = event.target.value.replace(/\D/g, '');
	const input = event.target.value.replace(/\D/g, '').substring(0, 10); // First ten digits of input only
	const areaCode = input.substring(0, 3);
	const middle = input.substring(3, 6);
	const last = input.substring(6, 10);

	if (input.length > 6) {
		event.target.value = `(${areaCode}) ${middle} - ${last}`;
	} else if (input.length > 3) {
		event.target.value = `(${areaCode}) ${middle}`;
	} else if (input.length > 0) {
		event.target.value = `(${areaCode}`;
	}
	return event.target.value;
}
export function validateEmail(event: React.ChangeEvent<HTMLInputElement>) {
	const re = /\S+@\S+\.\S+/;
	const errorMessage = event.target.nextElementSibling as HTMLDivElement;
	if (!re.test(event.target.value) && event.target.value !== '') {
		errorMessage.classList.add('active');
	} else {
		errorMessage.classList.remove('active');
	}
	return re.test(event.target.value);
}
export function validatePhone(event: React.ChangeEvent<HTMLInputElement>) {
	const re = /^\D?(\d{3})\D*?(\d{3})\D*?(\d{4})$/;
	const errorMessage = event.target.nextElementSibling as HTMLDivElement;
	if (!re.test(event.target.value) && event.target.value !== '') {
		errorMessage.classList.add('active');
	} else {
		errorMessage.classList.remove('active');
	}
	return re.test(event.target.value);
}
export function validateZip(event: React.ChangeEvent<HTMLInputElement>) {
	const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
	const errorMessage = event.target.nextElementSibling as HTMLDivElement;
	if (!re.test(event.target.value) && event.target.value !== '') {
		errorMessage.classList.add('active');
	} else {
		errorMessage.classList.remove('active');
	}
	return re.test(event.target.value);
}
export function validateAddress(address: string) {
	const re = /^[^,]+,\s*[^,]+,\s*[A-Z]{2},\s*USA$/;
	return re.test(address);
}

export function useLocalStorage(key: string, initialValue: boolean) {
	// Retrieve stored value or use initial value
	const storedValue = localStorage.getItem(key);
	const initial = storedValue ? JSON.parse(storedValue) : initialValue;

	const [value, setValue] = useState(initial);

	// Update local storage when value changes
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(value));
	}, [key, value]);

	return [value, setValue];
}

export async function sendVerificationSMS(twoFaBody: {
	phoneNumber: string;
	message: string;
}) {
	fetch('https://us-central1-transparent-rx.cloudfunctions.net/send2FACode', {
		// fetch('http://127.0.0.1:5001/transparent-rx/us-central1/send2FACode', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(twoFaBody),
	});
}

export async function deleteDocSF(fax: string, lname: string) {
	fetch('https://us-central1-transparent-rx.cloudfunctions.net/deleteDoc', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({ fax, lname }),
	});
}

export async function redirectToStripePayment(uniqueId: string) {
	const response = await fetch(
		'https://us-central1-transparent-rx.cloudfunctions.net/stripeInit',
		{
			method: 'POST',
			body: JSON.stringify({ uniqueId }),
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	const data = await response.json();
	// Assuming the response contains a URL for Stripe Checkout
	window.location.href = data.url;
}
export async function storeFormData(values: DocumentData, uniqueId: string) {
	const response = await fetch(
		'https://us-central1-transparent-rx.cloudfunctions.net/storeFormData',
		{
			method: 'POST',
			body: JSON.stringify({ values, uniqueId }),
			headers: {
				'Content-Type': 'application/json',
			},
		}
	);
	const data = await response.json();
	return data;
}

export function fplCalculator(household: number, income: number) {
	const yearlyRate = 15060;
	const theFactor = 5380; //added income for each additional person
	const fpl = Math.round(
		(income / (yearlyRate + (household - 1) * theFactor)) * 100
	);
	return fpl;
}

export function checkEligibilty(fpl: number, drugName: string) {
	switch (drugName) {
		case 'Abilify Maintena':
			return fpl <= 300;
		case 'Abilify Asimtufii':
			return fpl <= 300;
		case 'Adbry':
			return fpl <= 600;
		case 'Admelog':
			return fpl <= 500;
		case 'Admelog SoloStar':
			return fpl <= 500;
		case 'Aimovig':
			return fpl <= 290;
		case 'Anoro Ellipta':
			return fpl <= 300;
		case 'Basaglar':
			return fpl <= 500;
		case 'Biktarvy':
			return fpl <= 500;
		case 'Botox for migraines':
			return fpl <= 600;
		case 'Breo Ellipta':
			return fpl <= 300;
		case 'Cimzia':
			return fpl <= 500;
		case 'Combivent Respimat':
			return fpl <= 200;
		case 'Cosentyx':
			return fpl <= 550;
		case 'Creon - cystic fibrosis':
			return fpl <= 600;
		case 'Creon - not related to cystic fibrosis':
			return fpl <= 600;
		case 'Delstrigo':
			return fpl <= 400;
		case 'Dupixent for Asthma':
			return fpl <= 400;
		case 'Dupixent for Prurigo Nodularis':
			return fpl <= 400;
		case 'Dupixent for Rhinosinusitis with Nasal Polyposis':
			return fpl <= 400;
		case 'Dupixent for Eosinophilic Esophagitis':
			return fpl <= 400;
		case 'Dupixent for Atopic Dermatitis':
			return fpl <= 400;
		case 'Dupixent':
			return fpl <= 400;
		case 'Eliquis':
			return fpl <= 250;
		case 'Emgality':
			return fpl <= 400;
		case 'Enbrel':
			return fpl <= 290;
		case 'Entyvio':
			return fpl <= 500;
		case 'Entresto':
			return fpl <= 400;
		case 'Epclusa':
			return fpl <= 500;
		case 'Eucrisa':
			return fpl <= 300;
		case 'Fasenra':
			return fpl <= 300;
		case 'Forteo':
			return fpl <= 300;
		case 'HumaLOG':
			return fpl <= 500;
		case 'Humira':
			return fpl <= 600;
		case 'Humulin ':
			return fpl <= 400;
		case 'inFLIXimab':
			return fpl <= 600;
		case 'Invokana':
			return fpl <= 305;
		case 'Janumet':
			return fpl <= 400;
		case 'Janumet XR':
			return fpl <= 400;
		case 'Januvia':
			return fpl <= 400;
		case 'Jardiance':
			return fpl <= 250;
		case 'Kazano':
			return fpl <= 500;
		case 'Lantus':
			return fpl <= 400;
		case 'Linzess':
			return fpl <= 600;
		case 'Lovenox':
			return fpl <= 400;
		case 'Lyumjev':
			return fpl <= 400;
		case 'Motegrity':
			return fpl <= 500;
		case 'Multaq':
			return fpl <= 400;
		case 'Nesina':
			return fpl <= 500;
		case 'NovoLIN':
			return fpl <= 500;
		case 'NovoLOG':
			return fpl <= 400;
		case 'Nucala':
			return fpl <= 600;
		case 'Nurtec':
			return fpl <= 300;
		case 'Ocrevus':
			return fpl <= 1000;
		case 'Olumiant':
			return fpl <= 500;
		case 'Orencia':
			return fpl <= 300;
		case 'Ozempic':
			return fpl <= 400;
		case 'Qulipta':
			return fpl <= 600;
		case 'Remicade':
			return fpl <= 600;
		case 'Rexulti':
			return fpl <= 400;
		case 'Rinvoq':
			return fpl <= 600;
		case 'Rybelsus':
			return fpl <= 400;
		case 'Simponi':
			return fpl <= 600;
		case 'Simponi Aria':
			return fpl <= 600;
		case 'Skyrizi':
			return fpl <= 600;
		case 'Stelara':
			return fpl <= 600;
		case 'Stiolto Respimat':
			return fpl <= 200;
		case 'Symtuza':
			return fpl <= 300;
		case 'Taltz':
			return fpl <= 500;
		case 'Toujeo SoloStar':
			return fpl <= 400;
		case 'Trelegy Ellipta':
			return fpl <= 600;
		case 'Tremfya':
			return fpl <= 600;
		case 'Tresiba':
			return fpl <= 500;
		case 'Trintellix':
			return fpl <= 500;
		case 'Ubrelvy':
			return fpl <= 600;
		case 'Viberzi':
			return fpl <= 600;
		case 'Xarelto':
			return fpl <= 300;
		case 'Xeljanz':
			return fpl <= 500;
		case 'Zenpep':
			return fpl <= 400;
		case 'Zymfentra':
			return fpl <= 500;
		case 'Zepatier':
			return fpl <= 400;
		case 'Zubsolv':
			return fpl <= 300;
		default:
			return true;
	}
}

export const navigateWithQuery = (
	navigate: NavigateFunction,
	path: string,
	location: ReturnType<typeof useLocation>
) => {
	const query = new URLSearchParams(location.search);
	navigate(`${path}?${query.toString()}`);
};
